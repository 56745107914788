/*****************************
  Category
*****************************/

.categories {
	text-align: center;
	position: relative;
	overflow: hidden;
	margin-bottom: 30px;
	border-radius: $border-radius-sm; 
	&:before{
		background: linear-gradient(180deg, rgba(0,0,0,0) 71%, rgba(0,0,0,1) 100%);
		position: absolute;
		bottom: 0;
		margin: 0 auto;
		width: 100%;
		height: 100%;
		content: "";
		z-index: 1;
	}
		.categories-title{
			position: absolute;
			width: 100%;
			bottom: 0;
			padding: 10px;
			margin-bottom: 0;
			color: $white;
			z-index: 2;
			transition: all 0.3s ease-in-out;
	}
	img {
		transition: all 0.3s ease-in-out;
	}
	&:hover{
		.categories-title{
		color: $primary;
		}
		img {
			transform: scale(1.1);
		}
	}
}


/* Categories Style 02 */
.categories-style-02 {
	display: flex;
	flex-wrap: wrap;
	background: $white;
	overflow: inherit;
	box-shadow: $box-shadow-lg;
	border-radius: $border-radius-sm;
	&:before{
		content: none;
	}
	.categories-item {
		padding: 30px 15px;
		text-align: center;
		width: 16.6666%;
		border-right: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
		position: relative;
		cursor: pointer;
		border-radius: $border-radius-sm;
		&:nth-child(6n+0) {
			border-right: none;
		}
		&:before {
			content: "";
			background: $white;
			width: 110%;
			height: 110%;
			display: inline-block;
			position: absolute;
			left: -5%;
			top: -5%;
			z-index: 1;
			opacity: 0;
			transform: scale(0.96);
			transition: all 0.3s ease-in-out;			
		}
		&:hover {
			&:before {
				box-shadow: $box-shadow-lg;
				opacity: 1;
				transform: scale(1);
				border-radius: $border-radius-sm;
			}
			.categories-icon {
				color: $primary;
			}
			.categories-title {
				color: $primary;
			}
		}
		.categories-icon {
			color: $black;
			font-size: 44px;
			line-height: 1;
			margin-bottom: 10px;
			position: relative;
			z-index: 2;
				i {
					transition: all 0.3s ease-in-out;
				}
		}
		.categories-title {
			color: $gray-8;
			font-weight: 600;
			margin-bottom: 0;
			position: relative;
			z-index: 2;
			font-size: 16px;
			padding: 0px;
		}
		&:hover {
			.categories-title {
				color: $gray-7;
			}
		}
	}
	.show-more-cat {
		display: flex;
		justify-content: center;
		margin: 60px 0 -20px 0;
		width: 100%;
		a {
			color: $gray-7;
			font-size: 12px;
			font-weight: bold;
			&:hover {
				color: $primary;
			}
		}
		i {
			margin: 5px auto 0 auto;
			display: flex;
			width: 40px;
			height: 40px;
			text-align: center;
			background: $primary;
			border-radius: 50%;
			align-items: center;
			align-self: center;
			justify-content: center;
			color: $white;
		}
	}
}

/* Categories Style 03 */
.categories-style-03 {
	text-align: center;
	position: relative;
	overflow: hidden;
	border-radius: $border-radius-sm; 
	padding: 30px;
	&:before{
		position: absolute;
		top: 0;
		left: 0;
		margin: 0 auto;
		width: 100%;
		height: 100%;
		content: "";
		z-index: 0;
	}
	.categories-icon{
		position: relative;
		z-index: 99;
		i{
			color: $white;
		}
		.categories-title{
			line-height: 18px;
		}
	}
}


.my-shuffle-container{
	.grid-item{
		.categories{
      margin-bottom: 0;
   }
	}
}


/* Responsive View */
@media (max-width: 991px) {

	.categories-style-02 {
		.categories-item {
			width: 25%;
		}
	}
	.categories-style-02 {
		.categories-item {
			&:nth-child(6n+0) {
				border-right: 1px solid $border-color;
			}
			&:nth-child(4n+0) {
				border-right: none;
			}
		}
	}
}

@media (max-width: 767px) {

	.categories-style-02 {
		.categories-item {
			width: 33.3333%;
			&:nth-child(4n+0) {
				border-right: 1px solid $border-color;
			}
			&:nth-child(3n+0) {
				border-right: none;
			}
		}
		.show-more-cat {
			margin-top: 40px;
		}
	}

}

@media (max-width: 575px) {

	.categories-style-02 {
		.categories-item {
			width: 100%;
			&:nth-child(3n+0) {
				border-right: 1px solid $border-color;
			}
			&:nth-child(2n+0) {
				border-right: none;
			}
		}
	}


}