// Font family
$font-base: 		   'Quicksand', sans-serif;
$font-hedding:		 'Quicksand', sans-serif;

// Colors
$body-color:	    	#666666;
$primary:						#21c87a;
$white:							#ffffff;
$gray-1: 						#f9f9f9; 		// BG Light
$gray-2: 						#cccccc;
$gray-3: 						#666666; 		// Body Text
$gray-4: 						#999999;
$gray-5: 						#1c1a1f;    // Box Shadow
$gray-6: 						#f6f6f6;
$gray-7: 						#1c1a1f;    // Heading Color
$gray-8: 						#21c87a;
$gray-9: 						#1c1a1f; 		// BG Color Dark
$gray-10: 					#eaeaea;
$black:  						#ac9a9a;
$success:  					#0abb0a;
$danger:  					#f52626;
$warning:				#ffc107;

// Border color
$border-color: 	    #eaeaea;
$border-color-2: 	  #f9f9f9;

// For button and input border radius
$border-radius:     50px;
$border-radius-sm:  3px;

// Box shadow sizes
$box-shadow:       0px 3px 4px 0px rgba($gray-5, 0.1);
$box-shadow-lg:    0px 6px 10px 0px rgba($gray-5, 0.08);
