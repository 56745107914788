/*****************************
	Progress Bar
*****************************/
  /* Progress Bar Style 1 */
	.progress-style-1 {
		.progress-item {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0px;
			}
			.progress-title {
				color: $gray-9;
				font-size: 14px;
				font-weight: 700;
				margin-bottom: 5px;
			}
			.progress {
				overflow: inherit;
				background-color: $gray-10;
	     		border-radius: $border-radius-sm;
	     		position: relative;
     			span {
					color: $gray-9;
					font-weight: 700;
					font-size: 14px;
					line-height: 1;
					position: absolute;
					right: 0;
					bottom: 15px;
				}
				.progress-bar {
					overflow: inherit;
					background-color: $primary;
		    		border-radius: $border-radius-sm;

				}
			}
		}
	}


