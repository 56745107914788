/*****************************
  Event
*****************************/

.event {
  box-shadow: $box-shadow;
  border-radius: $border-radius-sm;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: $box-shadow-lg;
  }
  .event-img {
    position: relative;
      img {
        border-radius: 3px 3px 0px 0px;
      }
    .event-date {
      background-color: $primary;
      color: $white;
      width: 70px;
      font-size: 20px;
      font-weight: 700;
      padding-top: 5px;
      text-align: center;
      position: absolute;
      top: 20px;
      right: 20px;
      border-radius: $border-radius-sm;
      small {
        display: block;
        padding-bottom: 3px;
        text-transform: uppercase;
      }
      .years {
        display: block;
        font-size: 14px;
        font-weight: normal;
        padding: 5px 0px;
        background-color: $gray-3;
        border-radius: 0px 0px 3px 3px;
      }
    }
  }
  .event-info {
    padding: 20px;
    background: $white;
    .meta-event {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0px;
      margin-bottom: 10px;      
      li {
        list-style: none;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
        i {
          color: $primary;
          margin-right: 10px;
        }
      }
    }
    .event-title {
      margin-bottom: 0px;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        a {
          color: $primary;
        }
      }
    }
  }

  
}