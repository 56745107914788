/*

Template: Guruma - Online Course & Education HTML Template
Version: 1.0.0
Author: potenzaglobalsolutions
Design and Developed by: potenzaglobalsolutions.com

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template.

*/
/*================================================
[  Table of contents  ]
================================================

 :: Typography
 :: Helper Classes
 :: Shortcodes
    - Button
    - Accordion
    - shuffle
    - List
    - Feature info
    - Category
    - Gallery
    - Teachers
    - Testimonial
    - Countdown
    - Tabs
    - Owl carousel
    - Course
    - Progress Bar
    - Pricing
    - Event
    - Range Slider
    - Datetimepicker
 :: Header
   - Header Transparent 02
   - Header Transparent 03
 :: Banner
   - Banner 01
   - Banner 02
   - Banner 03
 :: Layout
   - Section Title
   - Client Logo
 :: Blog
   - Blog
 :: Shop
   - Shop
 :: Not Found
 :: Footer

======================================
[ End table content ]
======================================*/
/*****************************
  Typography
*****************************/
body {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
  overflow-x: hidden; }

a {
  outline: medium none !important;
  color: #21c87a;
  transition: all 0.3s ease-in-out; }
  a:focus {
    color: #1c1a1f;
    text-decoration: none !important; }
  a:hover {
    color: #1c1a1f;
    text-decoration: none !important; }

i {
  line-height: 1; }

hr {
  border-color: #eaeaea !important; }

input {
  outline: medium none !important;
  color: #21c87a; }

.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4,
.h5, h5,
.h6, h6 {
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  color: #1c1a1f;
  margin-top: 0px;
  letter-spacing: -1px; }
  .h1 a, h1 a,
  .h2 a, h2 a,
  .h3 a, h3 a,
  .h4 a, h4 a,
  .h5 a, h5 a,
  .h6 a, h6 a {
    color: inherit; }

label {
  font-weight: normal; }

.h1,
h1 {
  font-size: 72px;
  line-height: 82px; }

.h2,
h2 {
  font-size: 36px;
  line-height: 46px; }

.h3,
h3 {
  font-size: 30px;
  line-height: 40px; }

.h4,
h4 {
  font-size: 24px;
  line-height: 34px; }

.h5,
h5 {
  font-size: 20px;
  line-height: 30px; }

.h6,
h6 {
  font-size: 18px;
  line-height: 28px; }

.lead {
  font-size: 16px !important;
  line-height: 26px; }

small,
.small {
  font-size: 12px;
  font-weight: 500; }

*::-moz-selection {
  background: #21c87a;
  color: #ffffff;
  text-shadow: none; }

::-moz-selection {
  background: #21c87a;
  color: #ffffff;
  text-shadow: none; }

::selection {
  background: #21c87a;
  color: #ffffff;
  text-shadow: none; }

/* Flaticon */
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  margin-left: 0;
  font-size: inherit; }

/* Inner header */
.inner-header {
  padding: 60px 0; }
  .inner-header .breadcrumb {
    background: transparent;
    margin-bottom: 0;
    padding: 0; }
    .inner-header .breadcrumb .breadcrumb-item {
      font-size: 13px;
      font-weight: 600;
      padding-left: 10px; }
      .inner-header .breadcrumb .breadcrumb-item a {
        color: #21c87a; }
        .inner-header .breadcrumb .breadcrumb-item a:hover {
          color: #ffffff; }
    .inner-header .breadcrumb .breadcrumb-item.active {
      color: #ffffff; }
    .inner-header .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
      content: "\f105";
      font-family: "Font Awesome 5 free";
      padding-right: 10px;
      color: #ffffff; }
  .inner-header .breadcrumb-title {
    font-size: 50px;
    font-weight: 700;
    line-height: 50px; }

/*****************************
  Responsive
*****************************/
@media (max-width: 991px) {
  .h1,
  h1 {
    font-size: 42px;
    line-height: 52px; }
  .h2,
  h2 {
    font-size: 34px;
    line-height: 44px; }
  .h3,
  h3 {
    font-size: 32px;
    line-height: 42px; }
  .h4,
  h4 {
    font-size: 24px;
    line-height: 34px; }
  .h5,
  h5 {
    font-size: 22px;
    line-height: 32px; }
  /* Inner header */
  .inner-header {
    padding: 140px 0; }
    .inner-header .breadcrumb-title {
      font-size: 50px; } }

@media (max-width: 767px) {
  .h1,
  h1 {
    font-size: 32px;
    line-height: 42px; }
  .h2,
  h2 {
    font-size: 28px;
    line-height: 38px; }
  .h3,
  h3 {
    font-size: 26px;
    line-height: 36px; }
  .h4,
  h4 {
    font-size: 22px;
    line-height: 32px; }
  .h5,
  h5 {
    font-size: 20px;
    line-height: 30px; }
  .h6,
  h6 {
    font-size: 16px;
    line-height: 26px; }
  /* Inner header */
  .inner-header {
    padding: 100px 0; }
    .inner-header .breadcrumb-title {
      font-size: 40px; } }

@media (max-width: 575px) {
  .h1,
  h1 {
    font-size: 30px;
    line-height: 40px; }
  .h2,
  h2 {
    font-size: 26px;
    line-height: 36px; }
  .h3,
  h3 {
    font-size: 24px;
    line-height: 34px; }
  /* Inner header */
  .inner-header {
    padding: 50px 0; }
    .inner-header .breadcrumb {
      margin: 0 auto;
      text-align: center; }
    .inner-header .breadcrumb-title {
      font-size: 30px; } }

/*****************************
  Helper Classes
*****************************/
/* Color */
.text-primary {
  color: #21c87a !important; }

.text-white {
  color: #ffffff; }

.text-dark {
  color: #1c1a1f !important; }

.text-light {
  color: #666666 !important; }

.text-light-gray {
  color: #cccccc !important; }

.text-muted {
  color: #999999 !important; }

.text-body {
  color: #666666 !important; }

/* Background */
.bg-primary {
  background-color: #21c87a !important; }

.bg-dark {
  background-color: #1c1a1f !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-light {
  background-color: #f9f9f9 !important; }

.bg-success-soft {
  background: rgba(10, 187, 10, 0.1) !important; }

.bg-danger-soft {
  background: rgba(245, 38, 38, 0.1) !important; }

/* Box Shadow */
.box-shadow {
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1); }

.box-shadow-lg {
  box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08); }

/* Border Radius */
.rounded {
  border-radius: 50px !important; }

.rounded-sm {
  border-radius: 3px; }

/* Font Weight */
.fw-5 {
  font-weight: 500; }

.fw-6 {
  font-weight: 600; }

.fw-7 {
  font-weight: 700; }

/* Border */
.border-primary {
  border-color: #21c87a !important; }

.border-dark {
  border-color: #1c1a1f !important; }

.border-gray {
  border-color: #999999 !important; }

.border-color {
  border: 1px solid #eaeaea; }

.border-color-2 {
  border: 1px solid #f9f9f9; }

.border-top {
  border-color: #eaeaea !important; }

.border-bottom {
  border-color: #eaeaea !important; }

/* left right top */
.left-0 {
  left: 0; }

.right-0 {
  right: 0; }

.top-0 {
  top: 0; }

.bottom-0 {
  bottom: 0; }

/* Border width */
.border {
  border-color: #eaeaea !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

/* Page section padding */
.space-ptb {
  padding: 100px 0; }

.space-pt {
  padding-top: 100px; }

.space-pb {
  padding-bottom: 100px; }

.space-sm-ptb {
  padding: 70px 0; }

.space-sm-pt {
  padding-top: 70px; }

.space-sm-pb {
  padding-bottom: 70px; }

.h-100vh {
  height: 100vh !important; }

/* Half Overlay */
.half-overlay:before {
  content: "";
  background: #1c1a1f;
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  border-radius: 3px; }

.half-overlay.left-position:before {
  left: 0; }

.half-overlay.right-position:before {
  right: 0; }

.bg-overlay-left-100 {
  position: relative; }
  .bg-overlay-left-100:before {
    position: absolute;
    content: "";
    top: 0;
    left: -100%;
    background-color: #1c1a1f;
    width: 100%;
    height: 100%; }

.bg-overlay-right-100 {
  position: relative; }
  .bg-overlay-right-100:before {
    position: absolute;
    content: "";
    top: 0;
    right: -100%;
    background-color: #21c87a;
    width: 100%;
    height: 100%; }

/* Img Holder */
.bg-holder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%; }

/* Background overlay */
.bg-overlay-black-10 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-10:before {
    background: rgba(28, 26, 31, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-20 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-20:before {
    background: rgba(28, 26, 31, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-30 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-30:before {
    background: rgba(28, 26, 31, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-40 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-40:before {
    background: rgba(28, 26, 31, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-50 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-50:before {
    background: rgba(28, 26, 31, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-60 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-60:before {
    background: rgba(28, 26, 31, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-70 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-70:before {
    background: rgba(28, 26, 31, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-80 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-80:before {
    background: rgba(28, 26, 31, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-90 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-90:before {
    background: rgba(28, 26, 31, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-10 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-10:before {
    background: rgba(255, 255, 255, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-20 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-20:before {
    background: rgba(255, 255, 255, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-30 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-30:before {
    background: rgba(255, 255, 255, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-40 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-40:before {
    background: rgba(255, 255, 255, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-50 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-50:before {
    background: rgba(255, 255, 255, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-60 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-60:before {
    background: rgba(255, 255, 255, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-70 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-70:before {
    background: rgba(255, 255, 255, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-80 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-80:before {
    background: rgba(255, 255, 255, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-90 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-90:before {
    background: rgba(255, 255, 255, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-97 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-97:before {
    background: rgba(255, 255, 255, 0.97);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-theme-20 {
  position: relative;
  z-index: 1; }
  .bg-overlay-theme-20:before {
    background: rgba(33, 200, 122, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-theme-50 {
  position: relative;
  z-index: 1; }
  .bg-overlay-theme-50:before {
    background: rgba(33, 200, 122, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-theme-70 {
  position: relative;
  z-index: 1; }
  .bg-overlay-theme-70:before {
    background: rgba(33, 200, 122, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-theme-90 {
  position: relative;
  z-index: 1; }
  .bg-overlay-theme-90:before {
    background: rgba(33, 200, 122, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-theme-97 {
  position: relative;
  z-index: 1; }
  .bg-overlay-theme-97:before {
    background: rgba(33, 200, 122, 0.97);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-light-20 {
  position: relative;
  z-index: 1; }
  .bg-overlay-light-20:before {
    background: rgba(249, 249, 249, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-light-50 {
  position: relative;
  z-index: 1; }
  .bg-overlay-light-50:before {
    background: rgba(249, 249, 249, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-light-70 {
  position: relative;
  z-index: 1; }
  .bg-overlay-light-70:before {
    background: rgba(249, 249, 249, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-light-90 {
  position: relative;
  z-index: 1; }
  .bg-overlay-light-90:before {
    background: rgba(249, 249, 249, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-light-97 {
  position: relative;
  z-index: 1; }
  .bg-overlay-light-97:before {
    background: rgba(249, 249, 249, 0.97);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

/* Overlay */
.bg-blue-overlay:before {
  background: rgba(39, 114, 251, 0.8); }

.bg-purple-overlay:before {
  background: rgba(154, 39, 251, 0.8); }

.bg-green-overlay:before {
  background: rgba(19, 218, 145, 0.8); }

.bg-gold-overlay:before {
  background: rgba(251, 174, 39, 0.8); }

.bg-pink-overlay:before {
  background: rgba(251, 39, 159, 0.8); }

.bg-orange-overlay:before {
  background: rgba(251, 109, 39, 0.8); }

/* Avatar */
.avatar {
  min-width: 50px;
  width: 50px;
  display: inline-block; }

.avatar.avatar-sm {
  min-width: 20px;
  width: 20px; }

.avatar.avatar-md {
  min-width: 80px;
  width: 80px; }

.avatar.avatar-lg {
  min-width: 90px;
  width: 90px; }

/* Overflow */
.overflowx-h {
  overflow-x: hidden; }

/* Z index */
.z-index-1 {
  z-index: 1; }

.z-index-9 {
  z-index: 9; }

.z-index-99 {
  z-index: 99; }

.z-index-999 {
  z-index: 999; }

/* Opacity */
.opacity-1 {
  opacity: 0.1; }

.opacity-2 {
  opacity: 0.2; }

.opacity-3 {
  opacity: 0.3; }

.opacity-4 {
  opacity: 0.4; }

.opacity-5 {
  opacity: 0.5; }

.opacity-6 {
  opacity: 0.6; }

.opacity-7 {
  opacity: 0.7; }

.opacity-8 {
  opacity: 0.8; }

.opacity-9 {
  opacity: 0.9; }

.grayscale, .img-grayscale {
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out; }

.grayscale:hover {
  filter: inherit; }

/* Modal Dialog */
.modal-dialog .login-social-media .btn {
  padding: 10px 12px; }

/*****************************
  Responsive
*****************************/
@media (min-width: 576px) {
  /* Margin */
  .e-mt-sm-n4 {
    margin-top: -4rem !important; }
  .e-mt-sm-n5 {
    margin-top: -5rem !important; }
  .e-mt-sm-n6 {
    margin-top: -6rem !important; }
  .e-mt-sm-n7 {
    margin-top: -7rem !important; }
  .e-mt-sm-n8 {
    margin-top: -8rem !important; }
  .e-mt-sm-n9 {
    margin-top: -9rem !important; }
  .e-mt-sm-n10 {
    margin-top: -10rem !important; } }

@media (min-width: 768px) {
  /* Margin */
  .e-mt-md-n4 {
    margin-top: -4rem !important; }
  .e-mt-md-n5 {
    margin-top: -5rem !important; }
  .e-mt-md-n6 {
    margin-top: -6rem !important; }
  .e-mt-md-n7 {
    margin-top: -7rem !important; }
  .e-mt-md-n8 {
    margin-top: -8rem !important; }
  .e-mt-md-n9 {
    margin-top: -9rem !important; }
  .e-mt-md-n10 {
    margin-top: -10rem !important; } }

@media (min-width: 992px) {
  /* Margin */
  .e-mt-lg-n4 {
    margin-top: -4rem !important; }
  .e-mt-lg-n5 {
    margin-top: -5rem !important; }
  .e-mt-lg-n6 {
    margin-top: -6rem !important; }
  .e-mt-lg-n7 {
    margin-top: -7rem !important; }
  .e-mt-lg-n8 {
    margin-top: -8rem !important; }
  .e-mt-lg-n9 {
    margin-top: -9rem !important; }
  .e-mt-lg-n10 {
    margin-top: -10rem !important; } }

@media (min-width: 1200px) {
  /* Margin */
  .e-mt-xl-n4 {
    margin-top: -4rem !important; }
  .e-mt-xl-n5 {
    margin-top: -5rem !important; }
  .e-mt-xl-n6 {
    margin-top: -6rem !important; }
  .e-mt-xl-n7 {
    margin-top: -7rem !important; }
  .e-mt-xl-n8 {
    margin-top: -8rem !important; }
  .e-mt-xl-n9 {
    margin-top: -9rem !important; }
  .e-mt-xl-n10 {
    margin-top: -10rem !important; } }

@media (max-width: 1199px) {
  /* Page Section Padding */
  .space-ptb {
    padding: 70px 0; }
  .space-pt {
    padding-top: 70px; }
  .space-pb {
    padding-bottom: 70px; }
  .space-sm-ptb {
    padding: 60px 0; }
  .space-sm-pt {
    padding-top: 60px; }
  .space-sm-pb {
    padding-bottom: 60px; } }

@media (max-width: 991px) {
  /* Page Section Padding */
  .space-ptb {
    padding: 60px 0; }
  .space-pt {
    padding-top: 60px; }
  .space-pb {
    padding-bottom: 60px; }
  .space-sm-ptb {
    padding: 50px 0; }
  .space-sm-pt {
    padding-top: 50px; }
  .space-sm-pb {
    padding-bottom: 50px; }
  /* Half Overlay */
  .half-overlay:before {
    content: none; }
  /* Border */
  .border-lg-none {
    border: none !important; }
  /* Overlay */
  .bg-overlay-left-100:before,
  .bg-overlay-right-100:before {
    display: none; } }

@media (max-width: 767px) {
  /* Page Section Padding */
  .space-ptb {
    padding: 50px 0; }
  .space-pt {
    padding-top: 50px; }
  .space-pb {
    padding-bottom: 50px; }
  .space-sm-ptb {
    padding: 40px 0; }
  .space-sm-pt {
    padding-top: 40px; }
  .space-sm-pb {
    padding-bottom: 40px; }
  .container {
    max-width: 100%; }
  /* Border */
  .border-md-none {
    border: none !important; } }

@media (max-width: 575px) {
  /* Page Section Padding */
  .space-ptb {
    padding: 40px 0; }
  .space-pt {
    padding-top: 40px; }
  .space-pb {
    padding-bottom: 40px; }
  /* Border */
  .border-sm-none {
    border: none !important; } }

/*****************************
  Button
*****************************/
button {
  outline: medium none !important;
  color: #21c87a; }

/* Btn */
.btn {
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  padding: 13px 36px;
  font-weight: 600;
  border-radius: 50px;
  transition: all 0.3s ease-in-out; }
  .btn:not(:disabled):not(.disabled).active:focus {
    box-shadow: none;
    outline: none; }
  .btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    outline: none;
    color: #1c1a1f; }
  .btn:hover {
    box-shadow: none;
    outline: none; }
  .btn:focus {
    box-shadow: none;
    outline: none;
    color: #ffffff; }
  .btn:active {
    box-shadow: none;
    outline: none;
    color: #ffffff; }

.show > .btn.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
  color: #ffffff; }

.show > .btn-primary.dropdown-toggle {
  background: #21c87a;
  border-color: #21c87a; }

.btn-primary {
  background: #21c87a;
  border-color: #21c87a;
  color: #ffffff; }
  .btn-primary:hover {
    background: #1c1a1f;
    border-color: #1c1a1f;
    color: #ffffff; }
    .btn-primary:hover:not(:disabled):not(.disabled).active {
      background: #1c1a1f;
      border-color: #1c1a1f; }
    .btn-primary:hover:not(:disabled):not(.disabled):active {
      background: #1c1a1f;
      border-color: #1c1a1f; }

.btn-primary:not(:disabled):not(.disabled):active:focus {
  background: #1c1a1f;
  border-color: #1c1a1f;
  color: #21c87a; }

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: #21c87a;
  border-color: #21c87a; }

.btn-primary.focus,
.btn-primary:focus {
  background: #21c87a;
  border-color: #21c87a; }

.btn:not(:disabled):not(.disabled).active:focus {
  color: #ffffff; }

.btn + .btn {
  margin-left: 3px; }

.btn-dark {
  background-color: #1c1a1f;
  color: #ffffff;
  border-color: #1c1a1f; }
  .btn-dark:hover {
    background-color: #21c87a;
    border-color: #21c87a; }
  .btn-dark:focus {
    background-color: #21c87a;
    border-color: #21c87a; }
  .btn-dark:not(:disabled):not(.disabled).active {
    background-color: #21c87a;
    border-color: #21c87a; }
  .btn-dark:not(:disabled):not(.disabled):active {
    background-color: #21c87a;
    border-color: #21c87a; }

.btn-dark.focus {
  background-color: #21c87a;
  border-color: #21c87a; }

.show > .btn-dark.dropdown-toggle {
  background-color: #21c87a;
  border-color: #21c87a; }

/* Link */
.btn-link {
  color: #21c87a;
  padding: 0; }
  .btn-link:hover {
    color: #1c1a1f;
    text-decoration: none;
    outline: none; }
  .btn-link:focus {
    color: #1c1a1f;
    text-decoration: none;
    outline: none; }

.btn.btn-link:not(:disabled):not(.disabled):active:focus {
  color: #1c1a1f; }

.btn-light {
  background: #ffffff;
  border-color: #ffffff;
  color: #1c1a1f; }
  .btn-light:hover {
    background: #1c1a1f;
    border-color: #1c1a1f;
    color: #ffffff; }
  .btn-light:active {
    color: #ffffff; }
  .btn-light:focus {
    color: #ffffff; }

.btn.btn-light:not(:disabled):not(.disabled):active:focus {
  color: #1c1a1f; }

a.text-dark {
  color: #1c1a1f !important; }

a.text-dark:focus,
a.text-dark:hover {
  color: #21c87a !important;
  transition: all 0.3s ease-in-out; }

.btn-secondary {
  background-color: #cccccc;
  border-color: #cccccc;
  color: #1c1a1f; }
  .btn-secondary:hover {
    background-color: #21c87a;
    border-color: #21c87a; }
  .btn-secondary:focus {
    background-color: #21c87a;
    border-color: #21c87a; }
  .btn-secondary .focus {
    background-color: #21c87a;
    border-color: #21c87a; }
  .btn-secondary:not(:disabled):not(.disabled).active {
    background-color: #21c87a;
    border-color: #21c87a; }
  .btn-secondary:not(:disabled):not(.disabled):active {
    background-color: #21c87a;
    border-color: #21c87a; }

.show > .btn-secondary.dropdown-toggle {
  background-color: #21c87a;
  border-color: #21c87a; }

/* Button Size */
.btn.btn-sm {
  padding: 12px 22px;
  font-size: 13px;
  line-height: 18px; }

.btn.btn-md {
  padding: 14px 26px;
  line-height: 22px;
  font-size: 14px; }

.btn.btn-lg {
  padding: 16px 30px;
  font-size: 15px;
  line-height: 24px; }

.btn.btn-xl {
  padding: 18px 32px;
  font-size: 16px;
  line-height: 24px; }

/* Button Outline */
.btn-outline-primary {
  color: #ffffff;
  background: #21c87a;
  border-color: #21c87a; }
  .btn-outline-primary:hover {
    background: transparent;
    color: #21c87a;
    border-color: #21c87a; }
  .btn-outline-primary:focus {
    background: transparent;
    color: #21c87a;
    border-color: #21c87a; }

.btn-outline-primary:not(:disabled):not(.disabled):active {
  background: transparent;
  color: #21c87a;
  border-color: #21c87a; }

.btn.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  color: #21c87a; }

/* Button Outline Dark */
.btn-outline-dark {
  color: #ffffff;
  background: #1c1a1f;
  border-color: #1c1a1f; }
  .btn-outline-dark:hover {
    background: transparent;
    color: #1c1a1f;
    border-color: #1c1a1f; }
  .btn-outline-dark:focus {
    background: transparent;
    color: #1c1a1f;
    border-color: #1c1a1f; }

.btn-outline-dark:not(:disabled):not(.disabled):active {
  background: transparent;
  color: #1c1a1f;
  border-color: #1c1a1f; }

/* Button Outline Dark */
.btn-outline-dark-hover {
  color: #1c1a1f;
  background: transparent;
  border-color: #1c1a1f; }
  .btn-outline-dark-hover:hover {
    background: #1c1a1f;
    color: #ffffff;
    border-color: #1c1a1f; }
  .btn-outline-dark-hover:focus {
    background: #1c1a1f;
    color: #ffffff;
    border-color: #1c1a1f; }

.btn-outline-dark-hover:not(:disabled):not(.disabled):active {
  background: #1c1a1f;
  color: #ffffff;
  border-color: #1c1a1f; }

/* Button Outline */
.btn-outline-primary-hover {
  color: #21c87a;
  background: transparent;
  border-color: #21c87a; }
  .btn-outline-primary-hover:hover {
    background: #21c87a;
    color: #ffffff;
    border-color: #21c87a; }
  .btn-outline-primary-hover:focus {
    background: #21c87a;
    color: #ffffff;
    border-color: #21c87a; }

.btn-outline-primary-hover:not(:disabled):not(.disabled):active {
  background: transparent;
  color: #21c87a;
  border-color: #21c87a; }

/* btn app */
.btn-app {
  display: flex; }
  .btn-app i {
    font-size: 35px;
    padding-right: 10px; }
  .btn-app .btn-text {
    margin-top: -4px; }
    .btn-app .btn-text small {
      font-size: 10px;
      line-height: 10px; }
    .btn-app .btn-text span {
      line-height: 10px; }

/*****************************
  Shortcodes
*****************************/
/*****************************
  Accordion
*****************************/
.card {
  border: none;
  margin-bottom: 10px; }
  .card:last-child {
    margin-bottom: 0; }

.card-header {
  background: none;
  padding: 0;
  border-bottom: none; }

.accordion > .card .card-header {
  margin-bottom: 0; }
  .accordion > .card .card-header .btn {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    padding: 11px 45px 11px 26px;
    font-weight: bold;
    display: inline-block;
    width: 100%;
    background: #21c87a;
    color: #ffffff; }
    .accordion > .card .card-header .btn:after {
      font-family: "Font Awesome 5 Free";
      position: absolute;
      top: 12px;
      right: 25px;
      transition: all 0.3s ease-in-out;
      content: "\f068"; }
  .accordion > .card .card-header .btn.collapsed {
    background: none;
    color: #1c1a1f; }
    .accordion > .card .card-header .btn.collapsed:after {
      content: "\f067"; }

.card-body {
  padding: 20px 26px; }

/*****************************
  Shuffle
*****************************/
.filters-group {
  text-align: center; }
  .filters-group .btn-filter {
    background-color: transparent;
    color: #666666;
    padding: 9px 22px;
    border: none;
    font-weight: 500;
    border-radius: 50px;
    transition: all 0.3s ease-in-out; }
  .filters-group .btn-filter.active {
    background-color: #21c87a;
    color: #ffffff; }

.my-shuffle-container {
  margin-left: -15px;
  margin-right: -15px; }
  .my-shuffle-container .grid-item {
    padding: 15px; }

.my-shuffle-container.grid-3 .grid-item {
  width: 33.3333%; }

.my-shuffle-container.grid-4 .grid-item {
  width: 25%; }

/*****************************
  Responsive
*****************************/
@media (max-width: 1199px) {
  .my-shuffle-container.grid-4 .grid-item {
    width: 33.3333%; } }

@media (max-width: 767px) {
  .my-shuffle-container.grid-3 .grid-item {
    width: 50%; }
  .my-shuffle-container.grid-4 .grid-item {
    width: 50%; } }

@media (max-width: 575px) {
  .my-shuffle-container.grid-3 .grid-item {
    width: 100%; }
  .my-shuffle-container.grid-4 .grid-item {
    width: 100%; } }

/*****************************
  List
*****************************/
ul.list {
  padding: 0;
  margin: 0; }
  ul.list li {
    list-style: none;
    margin-bottom: 15px;
    display: flex; }
    ul.list li i {
      color: #21c87a;
      margin: 7px 5px 0px 0px;
      font-size: 10px; }
    ul.list li:last-child {
      margin-bottom: 0; }
    ul.list li a {
      color: #666666; }
      ul.list li a:hover {
        color: #21c87a; }

/*****************************
  Feature Info
*****************************/
/* Feature Info */
.feature-info .feature-info-icon {
  display: flex;
  justify-content: center;
  position: relative; }
  .feature-info .feature-info-icon i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 130px; }
  .feature-info .feature-info-icon img {
    position: absolute;
    right: 0px;
    top: 0px;
    transform: translate(58%, 50%); }

/* Feature Info 02 */
.feature-info-02 [class^='col-'] {
  border-right: 1px solid rgba(249, 249, 249, 0.23); }
  .feature-info-02 [class^='col-']:last-child {
    border-right: none; }

/* Feature Info 03 */
.feature-info-03 [class^='col-'] {
  border-right: 1px solid #eaeaea; }
  .feature-info-03 [class^='col-']:last-child {
    border-right: none; }

/* Feature Info 04 */
.feature-info-04 {
  background-color: #f9f9f9;
  padding: 40px 30px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out; }
  .feature-info-04 .feature-icon {
    color: #21c87a;
    transition: all 0.3s ease-in-out; }
  .feature-info-04 .feature-title {
    transition: all 0.3s ease-in-out; }
  .feature-info-04:hover {
    background-color: #21c87a;
    color: #ffffff; }
    .feature-info-04:hover .feature-icon {
      color: #ffffff; }
    .feature-info-04:hover .feature-title {
      color: #ffffff; }
    .feature-info-04:hover .btn-link {
      color: #ffffff; }
      .feature-info-04:hover .btn-link:hover {
        color: #1c1a1f; }

/* Feature Step */
.feature-step .feature-step-icon {
  display: flex;
  justify-content: center;
  position: relative; }
  .feature-step .feature-step-icon i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    color: #ffffff; }
  .feature-step .feature-step-icon img {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(69%, -50%); }

/*****************************
  Category
*****************************/
.categories {
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  border-radius: 3px; }
  .categories:before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 71%, black 100%);
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 1; }
  .categories .categories-title {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 10px;
    margin-bottom: 0;
    color: #ffffff;
    z-index: 2;
    transition: all 0.3s ease-in-out; }
  .categories img {
    transition: all 0.3s ease-in-out; }
  .categories:hover .categories-title {
    color: #21c87a; }
  .categories:hover img {
    transform: scale(1.1); }

/* Categories Style 02 */
.categories-style-02 {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  overflow: inherit;
  box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08);
  border-radius: 3px; }
  .categories-style-02:before {
    content: none; }
  .categories-style-02 .categories-item {
    padding: 30px 15px;
    text-align: center;
    width: 16.6666%;
    border-right: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    position: relative;
    cursor: pointer;
    border-radius: 3px; }
    .categories-style-02 .categories-item:nth-child(6n+0) {
      border-right: none; }
    .categories-style-02 .categories-item:before {
      content: "";
      background: #ffffff;
      width: 110%;
      height: 110%;
      display: inline-block;
      position: absolute;
      left: -5%;
      top: -5%;
      z-index: 1;
      opacity: 0;
      transform: scale(0.96);
      transition: all 0.3s ease-in-out; }
    .categories-style-02 .categories-item:hover:before {
      box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08);
      opacity: 1;
      transform: scale(1);
      border-radius: 3px; }
    .categories-style-02 .categories-item:hover .categories-icon {
      color: #21c87a; }
    .categories-style-02 .categories-item:hover .categories-title {
      color: #21c87a; }
    .categories-style-02 .categories-item .categories-icon {
      color: #ac9a9a;
      font-size: 44px;
      line-height: 1;
      margin-bottom: 10px;
      position: relative;
      z-index: 2; }
      .categories-style-02 .categories-item .categories-icon i {
        transition: all 0.3s ease-in-out; }
    .categories-style-02 .categories-item .categories-title {
      color: #21c87a;
      font-weight: 600;
      margin-bottom: 0;
      position: relative;
      z-index: 2;
      font-size: 16px;
      padding: 0px; }
    .categories-style-02 .categories-item:hover .categories-title {
      color: #1c1a1f; }
  .categories-style-02 .show-more-cat {
    display: flex;
    justify-content: center;
    margin: 60px 0 -20px 0;
    width: 100%; }
    .categories-style-02 .show-more-cat a {
      color: #1c1a1f;
      font-size: 12px;
      font-weight: bold; }
      .categories-style-02 .show-more-cat a:hover {
        color: #21c87a; }
    .categories-style-02 .show-more-cat i {
      margin: 5px auto 0 auto;
      display: flex;
      width: 40px;
      height: 40px;
      text-align: center;
      background: #21c87a;
      border-radius: 50%;
      align-items: center;
      align-self: center;
      justify-content: center;
      color: #ffffff; }

/* Categories Style 03 */
.categories-style-03 {
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  padding: 30px; }
  .categories-style-03:before {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 0; }
  .categories-style-03 .categories-icon {
    position: relative;
    z-index: 99; }
    .categories-style-03 .categories-icon i {
      color: #ffffff; }
    .categories-style-03 .categories-icon .categories-title {
      line-height: 18px; }

.my-shuffle-container .grid-item .categories {
  margin-bottom: 0; }

/* Responsive View */
@media (max-width: 991px) {
  .categories-style-02 .categories-item {
    width: 25%; }
  .categories-style-02 .categories-item:nth-child(6n+0) {
    border-right: 1px solid #eaeaea; }
  .categories-style-02 .categories-item:nth-child(4n+0) {
    border-right: none; } }

@media (max-width: 767px) {
  .categories-style-02 .categories-item {
    width: 33.3333%; }
    .categories-style-02 .categories-item:nth-child(4n+0) {
      border-right: 1px solid #eaeaea; }
    .categories-style-02 .categories-item:nth-child(3n+0) {
      border-right: none; }
  .categories-style-02 .show-more-cat {
    margin-top: 40px; } }

@media (max-width: 575px) {
  .categories-style-02 .categories-item {
    width: 100%; }
    .categories-style-02 .categories-item:nth-child(3n+0) {
      border-right: 1px solid #eaeaea; }
    .categories-style-02 .categories-item:nth-child(2n+0) {
      border-right: none; } }

/*****************************
  Gallery
*****************************/
.gallery-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px; }
  .gallery-item .gallery-overlay {
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    align-items: flex-end;
    transition: all 0.5s ease; }
    .gallery-item .gallery-overlay .gallery-description {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      .gallery-item .gallery-overlay .gallery-description .gallery-icon {
        position: relative;
        width: 40px;
        height: 40px;
        transition: all 0.5s ease; }
        .gallery-item .gallery-overlay .gallery-description .gallery-icon:before {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background-color: #1c1a1f;
          border-radius: 50px;
          transition: all 0.5s ease; }
        .gallery-item .gallery-overlay .gallery-description .gallery-icon i {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          line-height: 40px;
          text-align: center;
          transform: translate(-50%, -50%);
          z-index: 9;
          font-size: 14px;
          color: #21c87a;
          transition: all 0.5s ease; }
  .gallery-item:hover .gallery-overlay {
    opacity: 1;
    background: rgba(33, 200, 122, 0.5); }

/*****************************
  Teachers
*****************************/
.teachers {
  transition: all 0.5s ease-in-out;
  position: relative;
  cursor: pointer; }
  .teachers .content {
    text-align: center;
    padding: 20px;
    margin: -30px 0px 0;
    width: calc(100% - 30px);
    box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
    background-color: #ffffff;
    border-radius: 3px;
    position: relative;
    left: 15px;
    z-index: 9;
    transition: all 0.5s ease-in-out;
    display: block;
    overflow: hidden; }
    .teachers .content .name {
      margin-bottom: 5px; }
      .teachers .content .name a:hover {
        color: #21c87a; }
    .teachers .content p {
      margin-bottom: 0px; }
  .teachers .social-icon-round {
    margin-top: 0;
    transition: all 0.5s ease-in-out; }

.owl-carousel.teachers-shadow .owl-stage-outer {
  padding-bottom: 10px; }

/* Teachers style-02 */
.teachers-style-02 {
  text-align: center;
  border: 1px solid #eaeaea;
  border-radius: 3px; }
  .teachers-style-02 .content {
    padding: 15px; }
    .teachers-style-02 .content .avtar {
      margin-top: -70px;
      margin-bottom: 20px; }
      .teachers-style-02 .content .avtar img {
        border-radius: 50px;
        width: 100px;
        height: 100px;
        border: 2px solid #ffffff; }
    .teachers-style-02 .content .name {
      margin-bottom: 5px; }
      .teachers-style-02 .content .name:hover a {
        color: #21c87a; }
    .teachers-style-02 .content span {
      color: #21c87a; }
    .teachers-style-02 .content p {
      margin: 15px 0 20px; }

/* Teachers style-03 */
.teachers-style-03 {
  padding-bottom: 0px;
  border-radius: 3px;
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  cursor: inherit; }
  .teachers-style-03 img {
    border-radius: 3px 3px 0px 0px; }
  .teachers-style-03 .content {
    text-align: left;
    position: inherit;
    margin: 0;
    padding: 15px;
    width: inherit;
    left: inherit;
    box-shadow: none; }
    .teachers-style-03 .content ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 6px;
      margin-bottom: 0; }
      .teachers-style-03 .content ul li {
        margin-right: 20px; }
        .teachers-style-03 .content ul li a {
          color: #999999; }
          .teachers-style-03 .content ul li a:hover {
            color: #21c87a; }
        .teachers-style-03 .content ul li i {
          font-size: 12px;
          color: #21c87a;
          margin-right: 8px; }
    .teachers-style-03 .content .rating {
      font-size: 12px;
      font-weight: 600;
      margin-top: 5px; }
      .teachers-style-03 .content .rating a {
        color: #666666; }
        .teachers-style-03 .content .rating a:hover {
          color: #21c87a; }
      .teachers-style-03 .content .rating span {
        background-color: #ffc107;
        color: #ffffff;
        padding: 2px 7px;
        margin-right: 8px;
        border-radius: 3px; }
  .teachers-style-03:hover {
    box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08); }

/* Teacher Details */
.teacher-detail .btn-mes {
  color: #1c1a1f;
  border: 1px solid #1c1a1f; }
  .teacher-detail .btn-mes:hover {
    color: #21c87a;
    border-color: #21c87a; }

.teacher-detail .btn-fol {
  color: #21c87a;
  border: 1px solid #21c87a; }
  .teacher-detail .btn-fol:hover {
    color: #1c1a1f;
    border-color: #1c1a1f; }

/*****************************
    Testimonial
*****************************/
/* style 01 */
.testimonial {
  box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08); }
  .testimonial .testimonial-item {
    padding: 0 40px;
    margin-top: 80px;
    position: relative;
    z-index: 9; }
    .testimonial .testimonial-item .testimonial-content {
      margin-bottom: 50px; }
    .testimonial .testimonial-item .testimonial-author {
      margin-bottom: 20px; }
  .testimonial .item .svg-item svg {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: auto; }
  .testimonial .item .video-image img {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px; }

.testimonial.owl-carousel {
  border-radius: 3px; }
  .testimonial.owl-carousel .owl-nav {
    width: auto;
    display: inline-block;
    position: relative;
    bottom: 25px;
    padding: 0px;
    background: #ffffff;
    margin-top: 30px; }
    .testimonial.owl-carousel .owl-nav .owl-next {
      position: relative;
      top: 0;
      transform: inherit;
      right: 0; }
    .testimonial.owl-carousel .owl-nav .owl-prev {
      position: relative;
      top: 0;
      transform: inherit;
      left: 0; }
    .testimonial.owl-carousel .owl-nav i {
      font-size: 14px;
      padding: 0 10px; }
  .testimonial.owl-carousel .testimonial-img {
    position: relative; }

.video-image {
  position: relative; }

.popup-icon {
  display: block; }
  .popup-icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
    transition: all 0.3s ease-in-out;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    border: 7px solid #ffffff;
    color: #ffffff;
    border-radius: 50px; }
    .popup-icon i:hover {
      border: 7px solid #21c87a;
      color: #21c87a; }

.owl-carousel.testimonial-style-02 .author-img {
  margin-bottom: 30px; }
  .owl-carousel.testimonial-style-02 .author-img img {
    display: inline-block;
    width: 100px;
    border-radius: 50px;
    box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08); }

.owl-carousel.testimonial-style-02 .testimonial-content p {
  color: #1c1a1f;
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 30px; }

.owl-carousel.testimonial-style-02 .owl-nav i {
  color: #999999; }
  .owl-carousel.testimonial-style-02 .owl-nav i:hover {
    color: #21c87a;
    opacity: 1; }

.owl-carousel.testimonial-style-02 .owl-nav .owl-prev {
  left: -100px; }

.owl-carousel.testimonial-style-02 .owl-nav .owl-next {
  right: -100px; }

.owl-carousel.testimonial-center .owl-item {
  opacity: 0.1; }

.owl-carousel.testimonial-center .owl-item.active {
  opacity: 1; }

.owl-carousel.testimonial-center .owl-stage-outer {
  overflow: visible; }

.testimonial-style-03 {
  padding: 70px 0px;
  text-align: center;
  position: relative;
  margin: 0 30px; }
  .testimonial-style-03 .testimonial-info {
    padding: 70px 40px;
    box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
    border-radius: 3px;
    position: relative;
    background-color: #ffffff; }
    .testimonial-style-03 .testimonial-info .testimonial-quote {
      position: absolute;
      top: -60px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1; }
      .testimonial-style-03 .testimonial-info .testimonial-quote i {
        font-size: 90px; }
    .testimonial-style-03 .testimonial-info .testimonial-content {
      font-size: 18px;
      font-weight: 400;
      position: relative;
      margin-bottom: 50px; }
    .testimonial-style-03 .testimonial-info .testimonial-author {
      margin-bottom: -150px; }
      .testimonial-style-03 .testimonial-info .testimonial-author .avatar {
        position: inherit;
        top: 0px;
        left: inherit;
        margin-left: 0;
        margin-bottom: 20px;
        z-index: 99; }
        .testimonial-style-03 .testimonial-info .testimonial-author .avatar img {
          border-radius: 3px; }
      .testimonial-style-03 .testimonial-info .testimonial-author .testimonial-name h6 {
        font-weight: 500;
        font-size: 16px;
        margin-right: 10px;
        display: inline-block; }
      .testimonial-style-03 .testimonial-info .testimonial-author .testimonial-name span {
        font-size: 16px;
        color: #cccccc; }

@media (max-width: 991px) {
  .testimonial .testimonial-item {
    margin-top: 60px; }
    .testimonial .testimonial-item .testimonial-content {
      margin-bottom: 40px; }
  .owl-carousel.testimonial-style-02 .testimonial-content p {
    font-size: 20px;
    line-height: 30px; } }

@media (max-width: 767px) {
  .testimonial .testimonial-item {
    margin-top: 40px;
    padding: 0 20px; }
    .testimonial .testimonial-item .testimonial-content {
      margin-bottom: 30px; }
  .owl-carousel.testimonial-style-02 .testimonial-content p {
    font-size: 16px;
    line-height: 26px; }
  .owl-carousel.testimonial-style-02 .owl-nav {
    display: none; } }

@media (max-width: 575px) {
  .testimonial-style-03 .testimonial-info {
    padding: 50px 30px; } }

/*****************************
  Countdown
*****************************/
.countdown {
  display: inline-block;
  text-align: center;
  margin: 0 20px; }
  .countdown span {
    font-size: 70px;
    line-height: 1;
    color: #21c87a;
    font-weight: 700; }
  .countdown p {
    font-size: 18px;
    text-transform: capitalize; }

.countdown-style-2 .countdown {
  margin: 0 10px;
  padding: 30px;
  border-radius: 3px;
  width: 130px;
  height: 130px; }
  .countdown-style-2 .countdown h2 {
    font-size: 48px; }

@media (max-width: 1199px) {
  .countdown-style-2 .countdown {
    padding: 20px; }
  .countdown-style-2 .countdown {
    width: 110px;
    height: 110px; } }

@media (max-width: 767px) {
  .countdown span {
    font-size: 50px; } }

@media (max-width: 575px) {
  .countdown {
    margin: 0 10px; }
  .countdown span {
    font-size: 30px; }
  .countdown p {
    font-size: 14px; }
  .countdown-style-2 .countdown {
    padding: 20px;
    margin-bottom: 15px; } }

/*****************************
	Tab style
*****************************/
/* Tab */
.nav-tabs {
  border: none; }
  .nav-tabs .nav-link {
    padding: 9px 20px;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    color: #666666; }
    .nav-tabs .nav-link:focus {
      background: #21c87a;
      color: #ffffff; }
    .nav-tabs .nav-link:hover {
      background: #21c87a;
      color: #ffffff; }
  .nav-tabs .nav-item.show .nav-link {
    color: #ffffff;
    background: #21c87a; }
  .nav-tabs .nav-link.active {
    color: #ffffff;
    background: #21c87a; }
  .nav-tabs .nav-item .nav-link {
    border: none; }

/*****************************
	Owl Carousel
*****************************/
.owl-carousel .owl-item img {
  width: auto;
  display: inherit; }

.owl-carousel .owl-nav {
  cursor: pointer;
  z-index: 999;
  width: 100%; }
  .owl-carousel .owl-nav .owl-prev {
    display: block;
    position: absolute;
    text-indent: inherit;
    width: auto;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    left: 0px;
    display: block;
    position: absolute;
    text-indent: inherit;
    top: 55%;
    transform: translateY(-50%);
    margin: 0 !important;
    display: inline-block;
    margin: 14px 2px 0; }
  .owl-carousel .owl-nav .owl-next {
    display: block;
    position: absolute;
    text-indent: inherit;
    width: auto;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    right: 0px;
    display: block;
    position: absolute;
    text-indent: inherit;
    top: 55%;
    transform: translateY(-50%);
    margin: 0 !important;
    display: inline-block;
    margin: 14px 2px 0; }
  .owl-carousel .owl-nav i {
    font-size: 24px;
    transition: all 0.2s ease-in-out;
    color: #21c87a;
    opacity: 1; }
    .owl-carousel .owl-nav i:hover {
      color: #1c1a1f;
      opacity: 0.5; }

.row-background .owl-carousel .owl-nav i {
  color: #ffffff;
  opacity: 0.4; }
  .row-background .owl-carousel .owl-nav i:hover {
    color: #ffffff;
    opacity: 1; }

.owl-carousel .owl-dots {
  text-align: center;
  margin-top: 20px; }

.owl-carousel button.owl-dot {
  width: 9px;
  height: 9px;
  background: #1c1a1f !important;
  border-radius: 50%;
  margin-right: 10px;
  opacity: 0.5; }

.owl-carousel button.owl-dot.active {
  background: #21c87a !important;
  opacity: 1; }

@media (max-width: 991px) {
  .owl-carousel .owl-nav .owl-next {
    right: 0; }
  .owl-carousel .owl-nav .owl-prev {
    left: 0; } }

/*****************************
  Course
*****************************/
.course {
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  border-radius: 3px;
  transition: all 0.3s ease-in-out; }
  .course:hover {
    box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08); }
  .course .course-img {
    position: relative;
    overflow: hidden; }
    .course .course-img img {
      border-radius: 3px 3px 0px 0px;
      min-height: 100%;
      object-fit: cover;
      transition: all 0.3s ease-in-out; }
    .course .course-img:before {
      content: "";
      background: black;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 71%, black 100%);
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 1; }
    .course .course-img .course-category {
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      position: absolute;
      bottom: 6px;
      left: 10px;
      right: 10px;
      z-index: 2; }
      .course .course-img .course-category:hover {
        color: #21c87a; }
      .course .course-img .course-category i {
        color: #21c87a;
        margin-right: 6px; }
  .course .course-info {
    padding: 20px 15px 15px;
    background: #ffffff;
    border-radius: 0px 3px 3px; }
    .course .course-info .course-title a {
      font-size: 15px;
      font-weight: 600;
      color: #1c1a1f; }
      .course .course-info .course-title a:hover {
        color: #21c87a; }
    .course .course-info .course-instructor {
      color: #666666; }
    .course .course-info .course-rate-price {
      display: flex;
      justify-content: space-between;
      padding-top: 15px;
      margin-top: 22px;
      border-top: 1px solid #eaeaea; }
      .course .course-info .course-rate-price .rating {
        font-size: 12px;
        font-weight: 600; }
        .course .course-info .course-rate-price .rating a {
          color: #666666; }
          .course .course-info .course-rate-price .rating a:hover {
            color: #21c87a; }
        .course .course-info .course-rate-price .rating span {
          background-color: #ffc107;
          color: #ffffff;
          padding: 2px 7px;
          margin-right: 8px;
          border-radius: 3px; }
      .course .course-info .course-rate-price .price {
        font-size: 18px;
        color: #1c1a1f;
        font-weight: 600; }
  .course:hover .course-img img {
    transform: scale(1.1); }

/* Course Filter */
.course-filter {
  position: relative;
  background: #ffffff;
  padding: 10px;
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  border-radius: 3px; }
  .course-filter ul li {
    padding: 0px 14px;
    display: flex; }
    .course-filter ul li a {
      color: #666666;
      font-size: 12px; }
      .course-filter ul li a:hover {
        color: #21c87a; }
  .course-filter ul li.active a span {
    background: #21c87a; }
  .course-filter ul:nth-child(2n+2) li:last-child {
    border-right: none;
    padding-left: 0;
    padding-right: 0px; }
  .course-filter .course-short .select2-container {
    min-width: auto;
    margin-left: 30px; }
  .course-filter .course-view-list {
    margin-left: auto;
    align-items: center; }
  .course-filter .course-list-icon {
    display: block;
    background: rgba(33, 200, 122, 0.1);
    padding: 10px 10px 8px;
    border-radius: 3px; }
    .course-filter .course-list-icon span {
      margin: 0 auto;
      background: #21c87a;
      height: 2px;
      width: 10px;
      border-radius: 3px;
      margin-bottom: 2px;
      display: block; }
      .course-filter .course-list-icon span:nth-child(2) {
        width: 16px; }
    .course-filter .course-list-icon:hover,
    .course-filter .course-list-icon .active {
      background: #21c87a; }
      .course-filter .course-list-icon:hover span,
      .course-filter .course-list-icon .active span {
        background: #ffffff; }
  .course-filter .course-list-icon.active {
    background: #21c87a; }
    .course-filter .course-list-icon.active span {
      background: #ffffff; }
  .course-filter .course-grid-icon {
    display: flex;
    background: rgba(33, 200, 122, 0.1);
    color: #21c87a;
    padding: 10px 10px 8px;
    border-radius: 3px; }
    .course-filter .course-grid-icon span {
      background: #21c87a;
      width: 2px;
      height: 10px;
      border-radius: 3px;
      margin: 0 1px;
      display: inline-block; }
      .course-filter .course-grid-icon span:nth-child(2) {
        height: 16px;
        margin-top: -3px; }
    .course-filter .course-grid-icon:hover {
      background: #21c87a; }
      .course-filter .course-grid-icon:hover span {
        background: #ffffff; }
  .course-filter .course-grid-icon.active {
    background: #21c87a; }
    .course-filter .course-grid-icon.active span {
      background: #ffffff; }
  .course-filter .select2-container .select2-selection--single {
    height: 40px;
    padding: 0px 15px; }
    .course-filter .select2-container .select2-selection--single .select2-selection__rendered {
      padding: 0px;
      line-height: 40px; }
    .course-filter .select2-container .select2-selection--single .select2-selection__arrow {
      height: 40px;
      right: 5px; }
      .course-filter .select2-container .select2-selection--single .select2-selection__arrow b {
        border-color: #666666 transparent transparent transparent; }
  .course-filter .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #666666 transparent; }

/* Course list */
.course-list .course {
  box-shadow: none; }
  .course-list .course .course-img:before {
    border-radius: 0px 0px 0px 3px; }
  .course-list .course .course-img img {
    border-radius: 3px 0px 0px 3px; }

/* Course Details */
.course-countdown {
  display: flex;
  margin-top: 50px;
  text-align: center; }
  .course-countdown .countdown {
    flex-grow: 1;
    width: 0px;
    margin: 0px 15px 0px 0px; }
    .course-countdown .countdown:last-child {
      margin-right: 0; }
    .course-countdown .countdown h4 {
      padding: 20px 0px;
      border: 1px solid #eaeaea;
      border-radius: 3px; }
    .course-countdown .countdown p {
      font-size: 14px; }

.meta-course {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px;
  margin-bottom: 20px; }
  .meta-course li {
    list-style: none;
    margin-right: 20px; }
    .meta-course li:last-child {
      margin-right: 0; }
    .meta-course li i {
      color: #21c87a;
      margin-right: 10px; }

.course-d-Teacher img {
  width: 40px;
  height: 40px;
  border-radius: 50px; }

.course-details .media-img {
  width: 100px;
  height: 100px;
  border-radius: 50px; }

.social-share {
  position: relative; }
  .social-share ul {
    display: flex;
    opacity: 0;
    background: #21c87a;
    border-radius: 3px;
    padding: 1px 2px;
    margin-bottom: 0;
    right: 10px;
    top: -3px;
    position: absolute;
    visibility: hidden;
    transition: all .3s ease-in-out; }
    .social-share ul:before {
      position: absolute;
      z-index: 999;
      content: "";
      right: -6px;
      top: 9px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 6px;
      border-color: transparent transparent transparent #21c87a; }
    .social-share ul li {
      line-height: 26px;
      display: inline;
      list-style-type: none; }
      .social-share ul li a {
        padding: 2px 10px 2px;
        font-size: 14px;
        color: #ffffff; }
        .social-share ul li a:hover {
          color: #1c1a1f; }
  .social-share:hover ul {
    opacity: 1;
    right: 25px;
    visibility: visible; }

.course-details .social-share ul {
  right: auto;
  left: 50%;
  top: -44px;
  transform: translate(-56%, 0%); }
  .course-details .social-share ul:before {
    bottom: -6px;
    top: auto;
    right: 50%;
    border-width: 6px 6px 0px 6px;
    border-color: #21c87a transparent transparent transparent;
    transform: translate(4px, 0px); }

.course-details .social-share:hover ul {
  top: -34px; }

/* property item map */
.si-content-wrapper {
  padding: 0; }

.custom-window.open .si-frame {
  box-shadow: none;
  overflow: visible; }

.map-canvas {
  width: 100%;
  height: 100%; }

.custom-window {
  top: 30px;
  width: 280px;
  transition: top 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  visibility: hidden; }
  .custom-window .si-content {
    overflow: visible; }

.custom-window.active {
  top: 0;
  opacity: 1;
  visibility: visible; }

.course-item-map {
  position: relative;
  max-height: inherit;
  padding: 180px 12px 12px 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  overflow: auto;
  z-index: 2; }
  .course-item-map .course-item-map-content .course-instructor {
    color: #666666; }
    .course-item-map .course-item-map-content .course-instructor a {
      color: #21c87a;
      margin-left: 3px; }
      .course-item-map .course-item-map-content .course-instructor a:hover {
        color: #1c1a1f; }
  .course-item-map .course-item-map-content .course-rate-price {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #eaeaea; }
    .course-item-map .course-item-map-content .course-rate-price .rating span {
      background-color: #ffc107;
      color: #ffffff;
      padding: 2px 7px;
      margin-right: 8px;
      border-radius: 3px; }
    .course-item-map .course-item-map-content .course-rate-price .rating a {
      color: #666666; }
      .course-item-map .course-item-map-content .course-rate-price .rating a:hover {
        color: #21c87a; }
    .course-item-map .course-item-map-content .course-rate-price .price {
      font-size: 18px;
      color: #1c1a1f;
      font-weight: 600; }

.si-pointer-bg-top {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1); }

.course-item-map-img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 180px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1; }

.course-item-map-title {
  margin-top: 12px;
  line-height: 22px; }

.course-item-map-price {
  margin-top: 8px;
  display: flex;
  align-items: center; }

.course-item-map-content p {
  margin: 0; }

.course-item-map-content * + p {
  margin-top: 1em; }

.course-item-map-content a {
  color: #666666; }
  .course-item-map-content a:hover {
    color: #21c87a; }
  .course-item-map-content a:focus {
    color: #21c87a; }
  .course-item-map-content a:active {
    color: #21c87a; }

.custom-close {
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 9;
  width: 24px;
  padding: 0 8px;
  height: 24px;
  border-radius: 3px;
  transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  border: 0;
  background-color: #21c87a;
  color: #ffffff;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.4s ease-in-out; }
  .custom-close:hover {
    background-color: #21c87a; }
  .custom-close:focus {
    background-color: #21c87a; }
  .custom-close:active {
    background-color: #21c87a; }

.course-map .map-canvas {
  height: 500px; }

/*****************************
  Responsive
*****************************/
@media (max-width: 1199px) {
  .course-filter .course-short .select2-container {
    width: 152px !important; } }

@media (max-width: 575px) {
  .course-details .media {
    display: inherit; }
    .course-details .media img {
      margin-right: 0;
      margin-bottom: 15px; }
  .course-list .course .course-img img {
    border-radius: 3px 3px 0px 0px; }
  .course-filter .course-short .select2-container {
    margin-left: 0; } }

/*****************************
	Progress Bar
*****************************/
/* Progress Bar Style 1 */
.progress-style-1 .progress-item {
  margin-bottom: 20px; }
  .progress-style-1 .progress-item:last-child {
    margin-bottom: 0px; }
  .progress-style-1 .progress-item .progress-title {
    color: #1c1a1f;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px; }
  .progress-style-1 .progress-item .progress {
    overflow: inherit;
    background-color: #eaeaea;
    border-radius: 3px;
    position: relative; }
    .progress-style-1 .progress-item .progress span {
      color: #1c1a1f;
      font-weight: 700;
      font-size: 14px;
      line-height: 1;
      position: absolute;
      right: 0;
      bottom: 15px; }
    .progress-style-1 .progress-item .progress .progress-bar {
      overflow: inherit;
      background-color: #21c87a;
      border-radius: 3px; }

/*****************************
  Pricing Table
*****************************/
.pricing {
  padding: 30px 20px;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1); }
  .pricing .pricing-title {
    margin-bottom: 20px; }
  .pricing .pricing-price {
    display: inline-block;
    position: relative;
    color: #666666;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 600; }
    .pricing .pricing-price sup {
      position: absolute;
      top: 15px;
      left: -16px;
      font-size: 24px;
      color: #1c1a1f;
      font-weight: 600; }
    .pricing .pricing-price strong {
      color: #1c1a1f;
      font-size: 50px;
      line-height: 50px;
      font-weight: 600; }
  .pricing .pricing-list {
    margin-top: 20px;
    margin-bottom: 30px; }
    .pricing .pricing-list li {
      padding: 10px 0;
      color: #1c1a1f; }
      .pricing .pricing-list li i {
        margin-right: 6px;
        font-size: 12px; }
      .pricing .pricing-list li:last-child {
        padding-bottom: 0; }

.pricing.active {
  background: #21c87a;
  color: #ffffff; }
  .pricing.active .pricing-list li {
    color: #ffffff; }
  .pricing.active .pricing-title {
    color: #ffffff; }
  .pricing.active .pricing-price {
    color: #ffffff; }
  .pricing.active sup {
    color: #ffffff; }
  .pricing.active strong {
    color: #ffffff; }

/*****************************
  Event
*****************************/
.event {
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  border-radius: 3px;
  transition: all 0.3s ease-in-out; }
  .event:hover {
    box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08); }
  .event .event-img {
    position: relative; }
    .event .event-img img {
      border-radius: 3px 3px 0px 0px; }
    .event .event-img .event-date {
      background-color: #21c87a;
      color: #ffffff;
      width: 70px;
      font-size: 20px;
      font-weight: 700;
      padding-top: 5px;
      text-align: center;
      position: absolute;
      top: 20px;
      right: 20px;
      border-radius: 3px; }
      .event .event-img .event-date small {
        display: block;
        padding-bottom: 3px;
        text-transform: uppercase; }
      .event .event-img .event-date .years {
        display: block;
        font-size: 14px;
        font-weight: normal;
        padding: 5px 0px;
        background-color: #666666;
        border-radius: 0px 0px 3px 3px; }
  .event .event-info {
    padding: 20px;
    background: #ffffff; }
    .event .event-info .meta-event {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0px;
      margin-bottom: 10px; }
      .event .event-info .meta-event li {
        list-style: none;
        margin-right: 15px; }
        .event .event-info .meta-event li:last-child {
          margin-right: 0; }
        .event .event-info .meta-event li i {
          color: #21c87a;
          margin-right: 10px; }
    .event .event-info .event-title {
      margin-bottom: 0px;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .event .event-info .event-title:hover a {
        color: #21c87a; }

/*****************************
	Range Slider
*****************************/
.irs--flat .irs-line {
  height: 6px; }

.irs--flat .irs-bar {
  background: #21c87a;
  height: 6px; }

.irs--flat .irs-from {
  background: transparent;
  color: #21c87a;
  font-size: 13px;
  font-weight: 600; }
  .irs--flat .irs-from:before {
    display: none; }

.irs--flat .irs-to {
  background: transparent;
  color: #21c87a;
  font-size: 13px;
  font-weight: 600; }
  .irs--flat .irs-to:before {
    display: none; }

.irs--flat .irs-single {
  background: transparent;
  color: #21c87a;
  font-size: 13px;
  font-weight: 600; }
  .irs--flat .irs-single:before {
    display: none; }

.irs--flat .irs-handle > i:first-child {
  background: #ffffff;
  background: #ffffff;
  width: 12px;
  width: 12px;
  height: 12px;
  height: 12px;
  border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #21c87a;
  border: 2px solid #21c87a;
  cursor: pointer;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.irs--flat .irs-handle:hover > i:first-child {
  background: #21c87a; }

.irs--flat .irs-handle.state_hover > i:first-child {
  background: #21c87a; }

/*****************************
  Datetimepicker
*****************************/
.bootstrap-datetimepicker-widget table td.active {
  background-color: #21c87a; }
  .bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #21c87a; }

.bootstrap-datetimepicker-widget table td.day {
  height: 30px;
  line-height: 30px;
  width: 40px;
  font-size: 14px; }

.bootstrap-datetimepicker-widget table th {
  font-size: 14px; }

.bootstrap-datetimepicker-widget table td span.active {
  background: #21c87a; }

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 19rem; }

.input-group.date .input-group-text {
  background-color: #f9f9f9;
  border-color: #eaeaea; }

/*****************************
  Header
*****************************/
/* Header */
.header .navbar {
  padding: 30px 0px; }
  .header .navbar .navbar-nav {
    align-items: center; }
    .header .navbar .navbar-nav li > a {
      align-items: center;
      display: flex;
      padding: 3px 0px;
      color: #666666;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 500; }
      .header .navbar .navbar-nav li > a:hover {
        color: #21c87a; }
      .header .navbar .navbar-nav li > a i {
        margin-left: 5px;
        font-size: 10px; }
    .header .navbar .navbar-nav .nav-link {
      font-weight: 600;
      font-size: 14px;
      padding: 12px 8px 12px 0;
      color: #666666;
      text-transform: capitalize;
      position: relative;
      left: 0;
      transition: all 0.3s ease-in-out; }
      .header .navbar .navbar-nav .nav-link:hover {
        color: #21c87a; }
      .header .navbar .navbar-nav .nav-link i {
        font-weight: bold; }
      .header .navbar .navbar-nav .nav-link:hover {
        color: #21c87a; }
  .header .navbar .nav-item {
    margin-right: 20px; }
    .header .navbar .nav-item:last-child {
      margin-right: 0px; }
  .header .navbar .nav-item.megamenu-nav-area {
    position: inherit; }
  .header .navbar .nav-item.active .nav-link {
    color: #21c87a; }

.header .navbar-brand {
  padding: 0px;
  margin-right: 0;
  color: #ffffff;
  text-align: left;
  flex: 0 0 190px;
  transition: none; }
  .header .navbar-brand img {
    height: 48px; }

.header.default-transparent {
  position: absolute;
  width: 100%;
  z-index: 3; }
  .header.default-transparent .navbar .navbar-nav .nav-link {
    color: #ffffff; }
    .header.default-transparent .navbar .navbar-nav .nav-link:hover {
      color: #21c87a; }
  .header.default-transparent .navbar .nav-item.active .nav-link {
    color: #21c87a; }

.header-position {
  padding-top: 108px; }

/* Header Transparent 02 */
.header.default-transparent-02 .navbar .nav-item.active .nav-link {
  color: #1c1a1f; }

.header.default-transparent-02 .navbar .navbar-nav .nav-link:hover {
  color: #1c1a1f; }

.header.default-transparent-02.is-sticky .navbar .nav-item.active .nav-link {
  color: #21c87a; }

.header.default-transparent-02.is-sticky .navbar .navbar-nav .nav-link:hover {
  color: #21c87a; }

/* Header Transparent 03 */
.header.default-transparent.default-transparent-03 .navbar-brand-sticky {
  display: none; }

.header.default-transparent.default-transparent-03 .navbar .navbar-nav .nav-link {
  color: #1c1a1f; }

.header.default-transparent.default-transparent-03 .navbar .nav-item.active .nav-link {
  color: #21c87a; }

.header.default-transparent.default-transparent-03 .navbar .navbar-nav .nav-link:hover {
  color: #21c87a; }

.header.default-transparent.default-transparent-03 .search-category {
  border: none; }
  .header.default-transparent.default-transparent-03 .search-category button {
    background: #21c87a;
    border: none;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    padding: 14px 25px 14px 15px;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.5s ease-in-out;
    color: #ffffff;
    border-radius: 0px 50px 50px 0px; }
  .header.default-transparent.default-transparent-03 .search-category .form-control {
    width: 260px;
    height: 50px;
    padding: 14px 70px 14px 20px;
    background: #ffffff;
    color: #666666; }
    .header.default-transparent.default-transparent-03 .search-category .form-control::placeholder {
      color: #666666; }

.header.default-transparent.default-transparent-03 .woo-action ul li > a {
  color: #1c1a1f; }
  .header.default-transparent.default-transparent-03 .woo-action ul li > a i {
    color: #21c87a; }

.header.default-transparent.default-transparent-03 .woo-action ul li.cart .dropdown-toggle .cart-icon i {
  color: #21c87a; }
  .header.default-transparent.default-transparent-03 .woo-action ul li.cart .dropdown-toggle .cart-icon i:hover {
    color: #ffffff; }

.header.default-transparent-03.is-sticky .navbar-brand {
  display: none; }

.header.default-transparent-03.is-sticky .navbar .navbar-nav .nav-link {
  color: #ffffff; }
  .header.default-transparent-03.is-sticky .navbar .navbar-nav .nav-link:hover {
    color: #21c87a; }

.header.default-transparent-03.is-sticky .navbar .navbar-nav .nav-item.active .nav-link {
  color: #21c87a; }

.header.default-transparent-03.is-sticky .woo-action ul li > a {
  color: #ffffff; }
  .header.default-transparent-03.is-sticky .woo-action ul li > a:hover {
    color: #21c87a; }

/* Header Sticky */
.header.is-sticky {
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
  background-color: #1c1a1f;
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  padding: 0px; }
  .header.is-sticky .topbar {
    display: none; }
  .header.is-sticky .navbar {
    padding: 25px 0px; }
    .header.is-sticky .navbar .navbar-brand-sticky {
      display: block; }

/* Mega Menu */
.megamenu {
  padding: 20px 20px;
  width: 100%;
  border-radius: 0px; }
  .megamenu > div > li > ul {
    padding: 0;
    margin: 0; }
    .megamenu > div > li > ul > li {
      list-style: none; }
      .megamenu > div > li > ul > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        line-height: 1.428571429;
        color: #1c1a1f;
        white-space: normal; }
        .megamenu > div > li > ul > li > a:hover {
          text-decoration: none;
          color: #1c1a1f;
          background-color: #f9f9f9; }
        .megamenu > div > li > ul > li > a:focus {
          text-decoration: none;
          color: #1c1a1f;
          background-color: #f9f9f9; }

.megamenu.disabled > a:hover {
  color: #f9f9f9;
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: not-allowed; }

.megamenu.disabled > a:focus {
  color: #f9f9f9;
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: not-allowed; }

.header .megamenu .dropdown-item {
  padding: 0px;
  font-weight: 500; }

.header li > .dropdown-item:focus {
  color: #21c87a;
  background: none; }

.header li > .dropdown-item:hover {
  color: #21c87a;
  background: none; }

.header .dropdown-item.active {
  background: none; }

.header .dropdown-item:active {
  background: none; }

.header .dropdown-item:focus {
  background: none; }

.header .dropdown-item:hover {
  background: none; }

.header .dropdown-toggle::after {
  content: none; }

.header .navbar-collapse {
  align-items: inherit; }

.header .navbar .dropdown-menu {
  padding: 0px 0px 15px 0px;
  z-index: 9999;
  border: none; }
  .header .navbar .dropdown-menu a.dropdown-item {
    min-width: 180px; }
  .header .navbar .dropdown-menu li a i {
    margin-left: auto;
    margin-right: 20px; }

.navbar .dropdown > .dropdown-menu li > a {
  font-size: 13px;
  padding: 0px;
  color: #666666;
  position: relative;
  letter-spacing: 1px; }
  .navbar .dropdown > .dropdown-menu li > a:hover {
    color: #21c87a; }
  .navbar .dropdown > .dropdown-menu li > a span {
    padding: 8px 20px; }

.navbar .dropdown > .dropdown-menu li:last-child {
  border-bottom: none; }
  .navbar .dropdown > .dropdown-menu li:last-child > a span:before {
    content: none; }

.navbar .dropdown > .dropdown-menu .nav-title {
  color: #1c1a1f;
  padding: 8px 20px 0px; }

.navbar .navbar-nav .dropdown-menu li.active > a span {
  color: #21c87a; }
  .navbar .navbar-nav .dropdown-menu li.active > a span:before {
    transform: scaleX(1);
    transform-origin: left center; }

/* Cart */
.header .woo-action > ul {
  margin: 0;
  display: flex;
  align-items: center; }
  .header .woo-action > ul li {
    display: inline-block;
    margin-right: 20px; }
    .header .woo-action > ul li:last-child {
      margin-right: 0px; }
    .header .woo-action > ul li a {
      color: #666666;
      font-size: 14px;
      font-weight: 700;
      position: relative; }
      .header .woo-action > ul li a i {
        font-size: 16px;
        color: #21c87a; }
      .header .woo-action > ul li a:hover {
        color: #21c87a; }
  .header .woo-action > ul li.cart .dropdown-toggle {
    background: transparent;
    border: none;
    color: #21c87a; }
    .header .woo-action > ul li.cart .dropdown-toggle:hover {
      color: #21c87a; }
    .header .woo-action > ul li.cart .dropdown-toggle .cart-icon i {
      width: 46px;
      height: 46px;
      font-size: 16px;
      border-radius: 50px;
      border: 2px solid rgba(172, 154, 154, 0.2);
      line-height: 43px;
      text-align: center;
      transition: all 0.3s ease-in-out; }
      .header .woo-action > ul li.cart .dropdown-toggle .cart-icon i:hover {
        color: #ffffff;
        border: 2px solid #21c87a;
        background: #21c87a; }
  .header .woo-action > ul li.cart .dropdown-menu-right {
    background-color: #ffffff;
    position: absolute;
    box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08);
    width: 315px;
    z-index: 9;
    transform: translate3d(0%, 10px, 0);
    transition: all 0.3s ease-in-out;
    transition-property: opacity, visibility, transform;
    transform-origin: top center;
    visibility: hidden;
    opacity: 0;
    display: block !important; }
    .header .woo-action > ul li.cart .dropdown-menu-right li {
      margin-right: 0;
      padding: 15px 40px 15px 15px;
      border-bottom: 1px solid #eaeaea;
      position: relative; }
      .header .woo-action > ul li.cart .dropdown-menu-right li .cart-info a {
        font-size: 14px;
        font-weight: 500; }
      .header .woo-action > ul li.cart .dropdown-menu-right li .remove-item {
        position: absolute;
        right: 15px;
        top: 15px; }
        .header .woo-action > ul li.cart .dropdown-menu-right li .remove-item i {
          font-size: 12px; }
      .header .woo-action > ul li.cart .dropdown-menu-right li img {
        height: 80px; }
  .header .woo-action > ul li.cart .cart-footer {
    padding: 15px; }
  .header .woo-action > ul li.cart:hover .dropdown-menu-right {
    transform: translate3d(0%, 0, 0);
    visibility: visible;
    opacity: 1; }

.header.default-transparent .woo-action ul li > a {
  color: #ffffff; }
  .header.default-transparent .woo-action ul li > a:hover {
    color: #21c87a; }
  .header.default-transparent .woo-action ul li > a i {
    color: #ffffff; }

.header.default-transparent .woo-action ul li.cart .dropdown-toggle {
  color: #ffffff; }
  .header.default-transparent .woo-action ul li.cart .dropdown-toggle .cart-icon i {
    border-color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s ease-in-out; }

/* Search Category */
.header .search-category {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 30px;
  border: 2px solid rgba(172, 154, 154, 0.2);
  border-radius: 50px; }
  .header .search-category .form-control {
    font-size: 14px;
    line-height: 1;
    color: #666666;
    padding: 14px 65px 14px 20px;
    width: 340px;
    height: 44px;
    background: transparent;
    border: none; }
    .header .search-category .form-control::placeholder {
      color: #666666; }
  .header .search-category .search-button {
    background: #21c87a;
    border: none;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    padding: 12px 25px 12px 15px;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.5s ease-in-out;
    color: #ffffff;
    border-radius: 0px 50px 50px 0px; }
    .header .search-category .search-button:hover {
      color: #1c1a1f; }
  .header .search-category .course-category {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #ffffff;
    padding: 10px 20px;
    margin-bottom: 0;
    border: none;
    cursor: pointer;
    border-right: 2px solid rgba(172, 154, 154, 0.2); }
    .header .search-category .course-category .select2-container--default .select2-selection--single {
      background: transparent;
      color: #ffffff;
      border: none;
      height: inherit; }
      .header .search-category .course-category .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: #666666;
        padding: 0;
        font-weight: 600;
        line-height: inherit; }
      .header .search-category .course-category .select2-container--default .select2-selection--single .select2-selection__arrow {
        width: 8px;
        height: 24px;
        top: 0;
        right: 0; }

.select2-container {
  border-radius: 50px; }
  .select2-container span {
    outline: 0; }

.select2-dropdown {
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  border: none;
  border-radius: 3px; }

.select2-results__option {
  padding: 8px 15px; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #21c87a; }

.select2-container--default .select2-search--dropdown {
  padding: 8px 15px; }
  .select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #cccccc; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #f9f9f9;
  color: #21c87a; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #cccccc transparent transparent transparent; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #cccccc transparent; }

.select2-container--open .select2-dropdown--below {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.select2-search--dropdown .select2-search__field {
  border-radius: 3px; }

.header.default-transparent .search-category {
  border: 2px solid rgba(255, 255, 255, 0.5); }
  .header.default-transparent .search-category .form-control {
    color: #ffffff; }
    .header.default-transparent .search-category .form-control::placeholder {
      color: #ffffff; }
  .header.default-transparent .search-category .course-category {
    border-right: 2px solid rgba(255, 255, 255, 0.5); }
    .header.default-transparent .search-category .course-category .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #ffffff; }

.header.is-sticky .navbar .navbar-nav .nav-link {
  color: #ffffff; }
  .header.is-sticky .navbar .navbar-nav .nav-link:hover {
    color: #21c87a; }

.header.is-sticky .navbar .nav-item.active .nav-link {
  color: #21c87a; }

.header.is-sticky .search-category {
  border-color: rgba(255, 255, 255, 0.5); }
  .header.is-sticky .search-category .form-control {
    color: #ffffff; }
    .header.is-sticky .search-category .form-control::placeholder {
      color: #ffffff; }
  .header.is-sticky .search-category .course-category {
    border-right-color: rgba(255, 255, 255, 0.5); }
    .header.is-sticky .search-category .course-category .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #ffffff; }

.header.is-sticky .woo-action ul li > a {
  color: #ffffff; }
  .header.is-sticky .woo-action ul li > a:hover {
    color: #21c87a; }

.header.is-sticky .woo-action ul li.cart .dropdown-toggle .cart-icon i {
  border-color: rgba(255, 255, 255, 0.5); }

/* Header Style 02 */
.header-style-02 .navbar-brand-sticky {
  display: none; }

.header-style-02.is-sticky .navbar-brand {
  display: none; }

/*****************************
  Responsive
*****************************/
@media (min-width: 1200px) {
  .navbar-nav .mega-menu {
    position: static; }
  .header .navbar .dropdown-menu {
    margin: 0px;
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 0px;
    border: none;
    left: 100%;
    transform: translate3d(-50%, 10px, 0);
    transition: all 0.3s ease-in-out;
    transition-property: opacity, visibility, transform;
    transform-origin: top center;
    box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08);
    visibility: hidden;
    opacity: 0;
    display: block !important;
    border-radius: 3px; }
    .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu {
      left: 100%;
      right: auto;
      transform: translate3d(0px, 10px, 0); }
    .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu.left-side {
      right: 100%;
      left: auto;
      transform: translate3d(-15px, 10px, 0); }
  .header .navbar .dropdown-menu.megamenu {
    left: 50%; }
  .header .navbar .dropdown:hover > .dropdown-menu {
    transform: translate3d(-50%, 0, 0);
    visibility: visible;
    opacity: 1;
    padding: 10px 0px; }
  .header .navbar li:hover > ul.dropdown-menu {
    visibility: visible;
    opacity: 1; }
  .dropdown-submenu {
    position: relative; }
    .dropdown-submenu > .dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -6px; } }

@media (min-width: 992px) {
  .header .navbar .dropdown-menu.dropdown-menu-md {
    min-width: 400px; }
  .header .navbar .dropdown-menu.dropdown-menu-lg {
    min-width: 600px; } }

@media (max-width: 1800px) {
  .header .search-category .form-control {
    width: 320px; } }

@media (max-width: 1600px) {
  .header .navbar-brand {
    flex: 0 0 170px; }
  .header .navbar .nav-item {
    margin-right: 15px; }
  .header .search-category {
    margin-right: 20px; }
    .header .search-category .form-control {
      width: 230px; }
  .course-category .select2-container {
    width: 120px !important; }
  .navbar .dropdown > .dropdown-menu li > a {
    letter-spacing: 0; } }

@media (max-width: 1366px) {
  .header .navbar-brand {
    flex: 0 0 170px; }
  .header .navbar .nav-item {
    margin-right: 15px; }
  .header .search-category {
    margin-right: 20px; }
    .header .search-category .course-category {
      display: none; }
    .header .search-category .form-control {
      width: 230px; }
  .course-category .select2-container {
    width: 110px !important; } }

@media (max-width: 1199px) {
  .header .navbar-brand {
    flex: 0 0 200px; }
  .header .navbar .nav-item .shop-bg {
    background-image: inherit;
    border: none; }
  .header .navbar .dropdown-menu {
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: scroll; }
  .header .search-category {
    display: none; }
  .navbar .dropdown > .dropdown-menu li > a {
    letter-spacing: 0; }
  .navbar .dropdown > .dropdown-menu li > a {
    letter-spacing: 0; } }

@media (max-width: 991px) {
  /* Header */
  .header .navbar-collapse {
    position: absolute;
    top: 100%;
    z-index: 999;
    background: #ffffff;
    width: 100%;
    left: 0;
    margin-left: 0px;
    box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1); }
  .header .navbar {
    padding: 20px 0px; }
    .header .navbar .navbar-nav {
      align-items: initial; }
      .header .navbar .navbar-nav .nav-link {
        color: #1c1a1f;
        padding: 10px 20px; }
        .header .navbar .navbar-nav .nav-link i {
          position: absolute;
          right: 20px; }
    .header .navbar .dropdown-menu {
      margin: 0px;
      font-size: 14px;
      border-radius: 0px;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: #f6f6f6;
      padding: 14px 10px;
      font-size: 13px; }
    .header .navbar .navbar-toggler {
      position: absolute;
      right: 0px;
      padding-right: 0;
      color: #ffffff; }
    .header .navbar .woo-action {
      margin-right: 40px; }
  .header .navbar-brand img {
    height: 40px; }
  .header.default-transparent .navbar .navbar-nav .nav-link {
    color: #1c1a1f; }
  .header.is-sticky .navbar {
    padding: 15px 0px; }
  /* Header Style 01 */
  .header .navbar {
    position: relative;
    background: #1c1a1f; }
    .header .navbar .navbar-nav .nav-item {
      margin-right: 0;
      border-bottom: 1px solid #f6f6f6; }
    .header .navbar .navbar-nav .nav-link {
      color: #1c1a1f;
      padding: 12px; }
  /* Header Style 02 */
  .header.header-style-02 .navbar-brand {
    display: none; }
  .header.header-style-02 .navbar-brand-sticky {
    display: block; }
  .header.header-style-02 .woo-action > ul li a {
    color: #ffffff; }
  .header.header-style-02.is-sticky .navbar .navbar-nav .nav-link {
    color: #1c1a1f; }
  .header.header-style-02.is-sticky .navbar .nav-item.active .nav-link {
    color: #21c87a; }
  /* Header Style 03 */
  .header.default-transparent.default-transparent-03 .navbar-brand {
    display: none; }
  .header.default-transparent.default-transparent-03 .navbar-brand-sticky {
    display: block; }
  .header.default-transparent.default-transparent-03 .woo-action ul li > a {
    color: #ffffff; }
  .header.default-transparent-03.is-sticky .navbar .navbar-nav .nav-link {
    color: #1c1a1f; } }

@media (max-width: 575px) {
  /* Header */
  .header .navbar {
    padding: 15px 0px; }
    .header .navbar .navbar-brand {
      flex: 0 0 130px;
      padding: 5px 0px; }
    .header .navbar .woo-action {
      margin-right: 30px; }
      .header .navbar .woo-action ul li > {
        line-height: 1; }
        .header .navbar .woo-action ul li > a {
          font-size: 0px; }
      .header .navbar .woo-action ul li.cart .dropdown-menu-right {
        width: 270px;
        right: -15px; }
      .header .navbar .woo-action ul li.cart .dropdown-toggle .cart-icon i {
        width: 40px;
        height: 40px;
        font-size: 14px;
        line-height: 40px; }
  .header.is-sticky .navbar {
    padding: 10px 0px; } }

/*****************************
  Banner
*****************************/
/* Banner 01 */
/* slide 01 */
.slider-01:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 117px;
  background: black;
  background: linear-gradient(180deg, #1c1a1f 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 2; }

.slider-01 .swiper-slide {
  height: 840px; }

/* Banner 02 */
.banner-04 {
  height: 803px;
  display: flex; }
  .banner-04 .content h2 {
    font-size: 54px;
    line-height: 60px; }
  .banner-04 .banner-shep {
    position: absolute;
    top: 0;
    right: 0; }
  .banner-04 figure {
    position: absolute;
    top: -2px;
    right: 0px;
    height: 100%;
    width: 1109px; }
  .banner-04 .form-inline .form-control {
    padding-right: 98px; }
    .banner-04 .form-inline .form-control:focus {
      border-color: #1c1a1f; }
  .banner-04 .form-inline .btn-dark:hover {
    background-color: #1c1a1f;
    border-color: #1c1a1f; }

/* Banner 03 */
.banner-05 {
  height: 840px;
  position: relative; }
  .banner-05 .content {
    position: absolute;
    top: 130px;
    left: 0;
    right: 0;
    width: 100%; }
    .banner-05 .content h1 {
      font-size: 54px;
      line-height: 64px; }

/* Bullet */
.swiper-pagination .swiper-pagination-bullet {
  background-color: #cccccc;
  width: 88px;
  height: 5px;
  border-radius: 0;
  opacity: 1;
  outline: none; }

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #21c87a; }

.swiper-pagination.swiper-pagination-left {
  text-align: left;
  margin: 0 auto;
  right: 0;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

/* Arrow */
.swiper-button-next {
  right: 53px;
  background-image: inherit !important;
  outline: none;
  width: 50px;
  height: 50px; }
  .swiper-button-next i {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(172, 154, 154, 0.2);
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    outline: none;
    border-radius: 50px;
    transition: all 0.3s ease-in-out; }
  .swiper-button-next:hover i {
    color: #ffffff;
    background-color: rgba(172, 154, 154, 0.5); }

.swiper-button-prev {
  left: 53px;
  background-image: inherit !important;
  outline: none;
  width: 50px;
  height: 50px; }
  .swiper-button-prev i {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(172, 154, 154, 0.2);
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    outline: none;
    border-radius: 50px;
    transition: all 0.3s ease-in-out; }
  .swiper-button-prev:hover i {
    color: #ffffff;
    background-color: rgba(172, 154, 154, 0.5); }

/*****************************
  Responsive
*****************************/
@media (min-width: 576px) {
  .swiper-pagination.swiper-pagination-left {
    max-width: 510px; } }

@media (min-width: 768px) {
  .swiper-pagination.swiper-pagination-left {
    max-width: 720px; } }

@media (min-width: 992px) {
  .swiper-pagination.swiper-pagination-left {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .swiper-pagination.swiper-pagination-left {
    max-width: 1270px; } }

@media (max-width: 1600px) {
  .banner-04 {
    height: 679px; }
    .banner-04 figure {
      width: 939px; } }

@media (max-width: 1366px) {
  .banner-04 .content
h2 {
    font-size: 40px;
    line-height: 50px; }
  .banner-05 {
    height: 650px; } }

@media (max-width: 1199px) {
  /* Home 01 */
  .slider-01 .swiper-slide {
    height: 491px; }
  /* Home 02 */
  .slider-02 .swiper-slide {
    height: 550px; }
  .slider-02 .slide-01 .guruma-rate {
    bottom: 25px;
    left: -40%; }
  /* Home 04 */
  .banner-04 {
    height: 580px; }
    .banner-04 .content h2 {
      font-size: 36px;
      line-height: 46px; }
    .banner-04 figure {
      width: 802px; }
  /* Home 05 */
  .banner-05 {
    height: 600px; }
    .banner-05 .content h1 {
      font-size: 40px;
      line-height: 56px; } }

@media (max-width: 991px) {
  /* Home 01 */
  /* Home 02 */
  .slider-02 .swiper-slide {
    height: 420px; }
  .slider-02 .slide-01 .guruma-rate {
    display: none; }
  /* Home 03 */
  .slider-03 .swiper-slide {
    height: 600px; }
  /* Home 04 */
  .banner-04 {
    height: 540px; }
    .banner-04 figure {
      width: 624px;
      top: 87px; }
  /* Home 05 */
  .banner-05 {
    height: 500px; }
    .banner-05 .content h1 {
      font-size: 36px;
      line-height: 46px; } }

@media (max-width: 767px) {
  /* Home 02 */
  .slider-02 .slide-02 {
    background-position: right 0; }
  /* Home 03 */
  .slider-03 .swiper-slide {
    height: 420px; }
  .slider-03 .banner-content img {
    display: none; }
  .swiper-pagination .swiper-pagination-bullet {
    width: 50px; }
  .swiper-button-next,
  .swiper-button-prev {
    display: none; }
  .swiper-pagination.swiper-pagination-left {
    padding-left: 30px; }
  /* Home 04 */
  .banner-04 {
    height: 400px; }
    .banner-04 .content {
      margin-top: 70px; }
      .banner-04 .content h2 {
        font-size: 26px;
        line-height: 36px; }
    .banner-04 figure {
      display: none; } }

@media (max-width: 575px) {
  /* Home 05 */
  .banner-05 {
    height: 480px; }
    .banner-05 .content {
      top: 100px; }
      .banner-05 .content h1 {
        font-size: 30px;
        line-height: 40px; } }

/*****************************
  Layout
*****************************/
/* Section Title */
.section-title {
  margin-bottom: 30px; }
  .section-title h2 {
    color: #1c1a1f;
    margin-bottom: 10px; }

/* Client Logo */
.our-clients .owl-item {
  text-align: center; }
  .our-clients .owl-item img {
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out; }
  .our-clients .owl-item:hover img {
    filter: inherit; }

/* Back To Top */
.back-to-top a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  right: 40px;
  width: 40px;
  height: 40px;
  margin: 0px;
  color: #ffffff;
  font-size: 16px;
  background: #21c87a;
  border-radius: 50px;
  box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08);
  transition: all 0.3s ease-in-out;
  z-index: 999; }
  .back-to-top a:hover {
    background: #1c1a1f;
    color: #ffffff; }
  .back-to-top a:focus {
    background: #1c1a1f;
    color: #ffffff; }

/* Social-icon */
.social-icon ul {
  display: inline-block;
  padding: 0;
  margin: 0; }
  .social-icon ul li {
    display: inline-block;
    margin: 0px 15px; }
    .social-icon ul li:first-child {
      margin-left: 0; }
    .social-icon ul li:last-child {
      margin-right: 0; }
    .social-icon ul li a {
      color: #1c1a1f;
      font-size: 14px; }
      .social-icon ul li a:hover {
        color: #21c87a; }

/* Social-icon */
.social-icon-round ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0; }
  .social-icon-round ul li {
    display: inline-block;
    margin: 0px 8px; }
    .social-icon-round ul li:first-child {
      margin-left: 0; }
    .social-icon-round ul li:last-child {
      margin-right: 0; }
    .social-icon-round ul li a {
      display: block;
      color: #21c87a;
      font-size: 16px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid #21c87a;
      border-radius: 50px;
      transition: all 0.5s ease; }
      .social-icon-round ul li a:hover {
        color: #ffffff;
        background: #21c87a; }

/* Social-icon-small */
.social-icon-round.icon-sm ul li a {
  font-size: 14px;
  width: 30px;
  height: 30px;
  line-height: 30px; }

/* Social Bg Color */
.social-bg-hover {
  position: relative;
  color: #ffffff; }
  .social-bg-hover:before {
    content: "";
    color: #ffffff;
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s ease-in-out;
    border-radius: 50px; }
  .social-bg-hover:hover {
    color: #ffffff; }
    .social-bg-hover:hover:before {
      background-color: rgba(0, 0, 0, 0.1); }
  .social-bg-hover span {
    position: relative; }

.facebook-bg {
  background-color: #445c8e; }

.twitter-bg {
  background-color: #43afe9; }

.google-bg {
  background-color: #dc0510; }

.instagram-bg {
  background-color: #DD2A7B; }

.linkedin-bg {
  background-color: #007eb3; }

.pinterest-bg {
  background-color: #E60023; }

.bg-blue {
  background-color: #2772fb; }

.bg-purple {
  background-color: #9a27fb; }

.bg-green {
  background-color: #13da91; }

.bg-gold {
  background-color: #fbae27; }

.bg-pink {
  background-color: #fb279f; }

.bg-orange {
  background-color: #fb6d27; }

/* Maintenance */
.maintenance-content {
  padding-top: 100px; }

/* Coming soon */
.coming-soon-top {
  position: absolute;
  left: 0;
  right: 0; }

.coming-soon-section {
  padding-top: 100px; }
  .coming-soon-section .coming-soon-icon i {
    font-size: 110px;
    color: #cccccc; }

/* Form */
.form-control {
  padding: 14px 20px;
  height: 50px;
  font-size: 14px;
  border-color: #eaeaea;
  transition: all 0.3s ease-in-out;
  color: #666666;
  border-radius: 50px; }
  .form-control:focus {
    box-shadow: none;
    border-color: #21c87a; }

.custom-control-input:checked ~ .custom-control-label:before {
  background: #21c87a;
  border-color: #21c87a; }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none; }

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #21c87a; }

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #21c87a;
  border-color: #21c87a; }

/* Form Fill */
.fill-form .form-control {
  background-color: #f9f9f9; }

/* Table */
.table-striped thead tr {
  background-color: #21c87a;
  color: #ffffff;
  font-size: 16px; }
  .table-striped thead tr th {
    font-weight: normal;
    border: none; }

.table-striped tbody tr td {
  color: #666666;
  border-top-color: #eaeaea; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent; }

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f9f9f9; }

/* Search Course */
.search-course .form-control {
  padding-right: 130px;
  border-color: #ffffff; }
  .search-course .form-control:focus {
    border-color: #21c87a; }

/* Select */
.select2-container .select2-selection--single {
  height: 50px;
  border-radius: 3px;
  border-color: #eaeaea; }
  .select2-container .select2-selection--single .select2-selection__rendered {
    color: #666666;
    line-height: 50px; }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding: 0px 30px; }
  .select2-container .select2-selection--single .select2-selection__arrow {
    height: 50px;
    right: 20px;
    width: 20px; }

.find-Course .select2-container {
  z-index: 9; }

/* Input Size */
.form-control-sm {
  height: 44px; }

.form-control-md {
  height: 52px; }

.form-control-lg {
  height: 58px; }

.form-control-xl {
  height: 62px;
  font-size: 1.45rem; }

/* Form Inline */
.form-inline {
  position: relative; }
  .form-inline .btn {
    position: absolute;
    top: 0;
    right: 0; }
  .form-inline .form-group {
    width: 100%; }
  .form-inline .form-control {
    width: 100%; }

.form-flat-style .form-control {
  background-color: #f9f9f9;
  border-color: #eaeaea;
  border-radius: 3px; }
  .form-flat-style .form-control:focus {
    border-color: #21c87a; }

/* form ratings */
.ratings {
  border: none;
  float: left; }

.ratings > input {
  display: none; }

.ratings > label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  content: "\f005";
  font-weight: 900; }

.ratings > .half:before {
  content: "\f089";
  position: absolute; }

.ratings > label {
  color: #ddd;
  float: right; }

.ratings > input:checked ~ label,
.ratings:not(:checked) > label:hover,
.ratings:not(:checked) > label:hover ~ label {
  color: #FFD700; }

.ratings > input:checked + label:hover,
.ratings > input:checked ~ label:hover,
.ratings > label:hover ~ input:checked ~ label,
.ratings > input:checked ~ label:hover ~ label {
  color: #FFED85; }

/* Newsletter */
.input-with-btn .form-control {
  padding-right: 124px; }

/* Newsletter Input Size */
.input-with-btn .form-control-sm {
  padding-right: 114px; }

.input-with-btn .form-control-md {
  padding-right: 124px; }

.input-with-btn .form-control-lg {
  padding-right: 146px; }

.input-with-btn .form-control-xl {
  padding-right: 156px; }

/* Zoom Effect */
.img-zoom {
  overflow: hidden;
  width: 202px; }
  .img-zoom img {
    transition: all 0.3s ease-in-out;
    height: 202px; }
    .img-zoom img:hover {
      transform: scale(1.1); }

/*****************************
  Responsive
*****************************/
@media (max-width: 1600px) {
  .find-Course .select2-container {
    width: 100% !important; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1170px; } }

@media (max-width: 1199px) {
  .social-icon ul li {
    margin: 0px 10px; } }

@media (max-width: 991px) {
  /* Section Title */
  .section-title {
    margin-bottom: 30px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 96%; } }

@media (max-width: 767px) {
  /* Section Title */
  .section-title {
    margin-bottom: 20px; }
  /* Stretch BG */
  .stretch-bg {
    border-radius: 30px 0 30px 0; }
  .stretch-bg.stretch-left:before {
    border-radius: 30px 0 30px 0; } }

@media (max-width: 575px) {
  .form-inline .btn {
    position: inherit; } }

/*****************************
  Blog
*****************************/
.blog-post .blog-post-image {
  margin-bottom: 15px; }

.blog-post .blog-post-info {
  display: flex;
  margin-bottom: 5px; }
  .blog-post .blog-post-info a {
    color: #666666;
    font-size: 12px;
    font-weight: 400;
    margin-right: 10px; }
    .blog-post .blog-post-info a:hover {
      color: #21c87a; }
    .blog-post .blog-post-info a i {
      margin-right: 5px; }

.blog-post .blog-post-details .blog-post-title a:hover {
  color: #21c87a; }

.blog-post .blog-post-details p {
  margin-bottom: 30px; }

/* Navigation */
.navigation .nav-links {
  display: flex;
  justify-content: space-between; }
  .navigation .nav-links .nav-previous {
    width: 47%;
    border: 1px solid #eaeaea;
    transition: all 0.5s ease-in-out;
    border-radius: 50px; }
    .navigation .nav-links .nav-previous a {
      display: flex;
      color: #666666; }
    .navigation .nav-links .nav-previous .pagi-text {
      padding: 12px 25px;
      transition: all 0.3s ease-in-out; }
    .navigation .nav-links .nav-previous:hover {
      background: #f9f9f9; }
      .navigation .nav-links .nav-previous:hover .pagi-text {
        background: #21c87a;
        color: #ffffff; }
      .navigation .nav-links .nav-previous:hover .nav-title {
        color: #21c87a; }
    .navigation .nav-links .nav-previous .nav-title {
      padding: 12px 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      transition: all 0.3s ease-in-out; }
    .navigation .nav-links .nav-previous .pagi-text {
      border-right: 1px solid #eaeaea;
      transition: all 0.5s ease-in-out;
      border-radius: 50px; }

.navigation .nav-links {
  display: flex;
  justify-content: space-between; }
  .navigation .nav-links .nav-next {
    width: 47%;
    border: 1px solid #eaeaea;
    text-align: right;
    transition: all 0.5s ease-in-out;
    border-radius: 50px; }
    .navigation .nav-links .nav-next a {
      display: flex;
      color: #666666;
      justify-content: flex-end; }
    .navigation .nav-links .nav-next .pagi-text {
      display: flex;
      padding: 12px 25px;
      border-left: 1px solid #eaeaea;
      transition: all 0.3s ease-in-out;
      border-radius: 50px; }
    .navigation .nav-links .nav-next:hover {
      background: #f9f9f9; }
      .navigation .nav-links .nav-next:hover .pagi-text {
        background: #21c87a;
        color: #ffffff;
        border-radius: 50px; }
      .navigation .nav-links .nav-next:hover .nav-title {
        color: #21c87a; }
    .navigation .nav-links .nav-next .nav-title {
      padding: 12px 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      transition: all 0.3s ease-in-out; }

/* Pagination */
.pagination .page-item {
  margin: 3px 6px; }
  .pagination .page-item .page-link {
    display: inline-block;
    border: 1px solid #eaeaea;
    padding: 0 10px;
    font-size: 15px;
    color: #666666;
    min-width: 46px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    border-radius: 50px; }
    .pagination .page-item .page-link:hover {
      background: transparent;
      color: #21c87a;
      border-color: #21c87a; }
    .pagination .page-item .page-link:focus {
      box-shadow: none; }

.pagination .page-item.active .page-link {
  background: transparent;
  color: #21c87a;
  border-color: #21c87a; }

/* Sidebar */
.blog-sidebar .widget {
  margin-bottom: 30px; }
  .blog-sidebar .widget:last-child {
    margin-bottom: 0; }
  .blog-sidebar .widget .recent-post-info a {
    color: #1c1a1f; }
    .blog-sidebar .widget .recent-post-info a:hover {
      color: #21c87a; }
  .blog-sidebar .widget .testimonial-style-02 .testimonial-item .author-img {
    margin-bottom: 20px; }
    .blog-sidebar .widget .testimonial-style-02 .testimonial-item .author-img img {
      width: 60px; }
  .blog-sidebar .widget .testimonial-style-02 .testimonial-item .testimonial-content p {
    font-size: 14px;
    line-height: 24px; }

/* blog post style 02 */
.blog-post-style-02 {
  position: relative;
  overflow: hidden;
  border-radius: 3px; }
  .blog-post-style-02:before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, black 100%);
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    content: "";
    border-radius: 3px;
    z-index: 1; }
  .blog-post-style-02 .blog-post-img img {
    border-radius: 3px;
    transition: all 0.3s ease-in-out; }
  .blog-post-style-02 .blog-post-info {
    position: absolute;
    display: block;
    bottom: 0;
    padding: 30px;
    width: 100%;
    z-index: 2; }
    .blog-post-style-02 .blog-post-info .blog-post-category {
      display: inline-block;
      background: #21c87a;
      padding: 0px 10px;
      font-size: 12px;
      border-radius: 3px;
      margin-bottom: 5px; }
      .blog-post-style-02 .blog-post-info .blog-post-category a {
        display: inline-block;
        color: #ffffff;
        margin-right: 3px; }
        .blog-post-style-02 .blog-post-info .blog-post-category a:last-child {
          margin-right: 0px; }
        .blog-post-style-02 .blog-post-info .blog-post-category a:hover {
          color: #1c1a1f; }
    .blog-post-style-02 .blog-post-info .blog-post-title {
      color: #ffffff;
      margin: 0; }
      .blog-post-style-02 .blog-post-info .blog-post-title:hover a {
        color: #21c87a; }
      .blog-post-style-02 .blog-post-info .blog-post-title a:focus {
        color: #21c87a; }
  .blog-post-style-02:hover .blog-post-img img {
    transform: scale(1.1); }

/* Blog Details */
.blog-details .blog-post-content .blog-post-footer .btn-link {
  color: #21c87a; }
  .blog-details .blog-post-content .blog-post-footer .btn-link:hover {
    color: #21c87a; }

/*****************************
  Responsive
*****************************/
@media (max-width: 991px) {
  .blog-post-style-02 .blog-post-info {
    padding: 15px; } }

@media (max-width: 575px) {
  /* Blog */
  .navigation .nav-links {
    display: block; }
    .navigation .nav-links .nav-previous {
      width: 100%; }
    .navigation .nav-links .nav-next {
      width: 100%;
      margin-top: 5px; }
  .pagination .page-item .page-link {
    min-width: 30px;
    width: 30px;
    height: 30px;
    line-height: 30px; }
  .blog-post-style-02 .news-info .news-title {
    font-size: 16px; } }

/*****************************
  Sidebar
*****************************/
/* Sidebar */
.widgets .widget {
  margin-bottom: 50px;
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1); }
  .widgets .widget:last-child {
    margin-bottom: 0px; }

.widgets .widget-title {
  background-color: #f9f9f9;
  padding: 10px 20px;
  margin-bottom: 0; }

.widgets .widget-content {
  padding: 30px 20px;
  border-top: 1px solid #eaeaea; }

.widget-tag ul {
  display: flex;
  flex-wrap: wrap; }
  .widget-tag ul li {
    margin: 0px 15px 15px 0px; }
    .widget-tag ul li a {
      display: inline-block;
      padding: 8px 25px;
      border: 1px solid #eaeaea;
      border-radius: 50px; }
      .widget-tag ul li a:hover {
        border-color: #21c87a; }

.widget-post ul {
  padding-left: 0px;
  margin-bottom: 0px; }
  .widget-post ul li {
    display: flex;
    margin-bottom: 15px;
    margin-left: 0; }
    .widget-post ul li:last-child {
      margin-bottom: 0; }
    .widget-post ul li img {
      width: 60px;
      margin-right: 15px;
      border-radius: 3px; }

.widget-course-info ul li {
  display: flex;
  color: #1c1a1f;
  min-width: 120px; }

.widget-post .widget-post-title a {
  color: #666666; }
  .widget-post .widget-post-title a:hover {
    color: #21c87a; }

/*****************************
  Responsive
*****************************/
@media (max-width: 767px) {
  .widgets .widget {
    margin-bottom: 30px; } }

/*****************************
  Shop
*****************************/
/* Product */
.product .product-image {
  position: relative; }
  .product .product-image .product-overlay {
    opacity: 0;
    text-align: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 9;
    margin: 0 auto;
    transition: all 0.5s ease-in-out; }
    .product .product-image .product-overlay .add-to-cart a {
      background: #21c87a;
      color: #ffffff;
      padding: 10px 20px;
      border-radius: 3px; }

.product:hover .product-image .product-overlay {
  opacity: 1; }

.product .product-description {
  text-align: center; }
  .product .product-description .product-title {
    margin: 20px 0px 10px; }
    .product .product-description .product-title a {
      font-size: 16px;
      font-weight: 700;
      color: #1c1a1f; }
      .product .product-description .product-title a:hover {
        color: #21c87a; }
  .product .product-description .product-rating {
    margin-bottom: 10px; }
    .product .product-description .product-rating i {
      color: #ffb100; }
  .product .product-description .product-price {
    font-size: 14px;
    font-weight: 700; }
    .product .product-description .product-price del {
      color: #cccccc; }
    .product .product-description .product-price ins {
      color: #21c87a;
      text-decoration: none; }

/* Product Detail */
.shop-single .nav-item .nav-link {
  font-size: 16px; }

.shop-single .product-detail .product-price-rating .product-price {
  margin-bottom: 20px; }
  .shop-single .product-detail .product-price-rating .product-price h4 {
    font-size: 20px;
    margin-bottom: 0; }
    .shop-single .product-detail .product-price-rating .product-price h4 span {
      font-size: 24px;
      color: #21c87a;
      margin-left: 8px; }

.shop-single .product-detail .product-price-rating .product-rating {
  margin: 20px 0; }
  .shop-single .product-detail .product-price-rating .product-rating i {
    color: #21c87a; }

.shop-single .product-detail .product-summary {
  margin-top: 30px; }
  .shop-single .product-detail .product-summary a {
    color: #21c87a;
    margin-right: 10px; }
    .shop-single .product-detail .product-summary a i {
      color: #21c87a;
      margin-right: 10px; }
    .shop-single .product-detail .product-summary a:hover {
      color: #21c87a; }

.shop-single .product-detail hr {
  margin: 30px 0; }

.shop-single .product-detail .product-detail-meta {
  margin-bottom: 20px; }
  .shop-single .product-detail .product-detail-meta span {
    display: block;
    margin: 10px 0; }

.shop-single .product-detail .product-detail-social {
  display: flex;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 0; }
  .shop-single .product-detail .product-detail-social span {
    font-weight: 700; }
  .shop-single .product-detail .product-detail-social ul {
    display: flex;
    flex-wrap: wrap;
    padding-left: 15px;
    margin-bottom: 0; }
    .shop-single .product-detail .product-detail-social ul li {
      list-style-type: none;
      margin-right: 20px;
      font-size: 16px; }

.shop-single .product-detail .input-group {
  width: 90px;
  float: left;
  margin-right: 20px; }

.form-group .product-rating {
  color: #21c87a; }

.slider-slick .slider-nav .slick-next:before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  z-index: 999;
  color: #ffffff;
  font-size: 14px; }

.slider-slick .slider-nav .slick-prev:before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  z-index: 999;
  color: #ffffff;
  font-size: 14px; }

.slider-slick .slider-nav:hover .slick-prev {
  left: 0;
  z-index: 99;
  opacity: 1; }

.slider-slick .slider-nav:hover .slick-next {
  right: 0;
  opacity: 1; }

.slider-slick .slider-nav .slick-prev {
  opacity: 0;
  width: 40px;
  height: 40px;
  background: #21c87a;
  color: #21c87a;
  transition: all 0.3s ease;
  border-radius: 3px; }
  .slider-slick .slider-nav .slick-prev:hover {
    background: #1c1a1f; }

.slider-slick .slider-nav .slick-next {
  opacity: 0;
  width: 40px;
  height: 40px;
  background: #21c87a;
  color: #21c87a;
  transition: all 0.3s ease;
  border-radius: 3px; }
  .slider-slick .slider-nav .slick-next:hover {
    background: #1c1a1f; }

.slider-slick .slider-nav .slick-track .slick-slide {
  margin-left: 5px;
  margin-right: 5px; }

/* Comment List */
.commentlist {
  display: flex; }
  .commentlist .comment-content {
    border: 1px solid #eaeaea;
    padding: 15px;
    margin-left: 20px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 3px; }
    .commentlist .comment-content .reviews {
      display: flex; }
      .commentlist .comment-content .reviews .rating {
        margin-left: auto; }
        .commentlist .comment-content .reviews .rating i {
          color: #21c87a; }
  .commentlist .comment-author img {
    width: 60px; }

.form-check-label {
  padding-left: 20px; }

/* Shop Sidebar */
.shop-sidebar .widget {
  margin-bottom: 30px; }
  .shop-sidebar .widget .price-filter {
    display: flex; }
    .shop-sidebar .widget .price-filter a {
      margin-left: auto; }
  .shop-sidebar .widget .form-check {
    display: block;
    margin-bottom: 5px; }
    .shop-sidebar .widget .form-check:last-child {
      margin-bottom: 0; }
    .shop-sidebar .widget .form-check span {
      float: right;
      color: #21c87a; }
  .shop-sidebar .widget .custom-checkbox .custom-control-label::before {
    border-color: #eaeaea; }
  .shop-sidebar .widget .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none; }
  .shop-sidebar .widget .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    background-color: #21c87a; }
  .shop-sidebar .widget .social ul li {
    display: inline-block;
    list-style-type: none;
    margin-right: 20px;
    font-size: 16px; }
  .shop-sidebar .widget .tagcloud ul li {
    margin-bottom: 10px;
    display: inline-block; }
    .shop-sidebar .widget .tagcloud ul li a {
      color: #666666;
      line-height: 32px;
      border: 1px solid #eaeaea;
      padding: 2px 20px;
      margin-right: 6px;
      border-radius: 3px;
      display: block;
      font-size: 13px; }
      .shop-sidebar .widget .tagcloud ul li a:hover {
        background: #21c87a;
        border: 1px solid #21c87a;
        color: #ffffff; }
  .shop-sidebar .widget .widget-categories ul li a {
    font-size: 14px;
    color: #1c1a1f;
    line-height: 32px;
    display: block; }
    .shop-sidebar .widget .widget-categories ul li a:hover {
      color: #21c87a; }

.search-field {
  padding: 14px 20px;
  color: #666666;
  box-shadow: none;
  border: 1px solid #eaeaea;
  height: 52px;
  border-radius: 3px;
  width: 100%; }

/* Checkout */
.checkout-info {
  padding: 25px 25px 25px 120px;
  background: #f9f9f9;
  position: relative;
  border-radius: 3px; }
  .checkout-info:before {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 25px;
    content: "\f2f6";
    font-size: 60px;
    font-family: "Font Awesome 5 Free";
    z-index: 9;
    color: #cccccc;
    font-weight: 700; }

.checkout-info-coupon .checkout-info:before {
  content: "\f3ff"; }

.checkout-info-coupon .checkout-coupon {
  padding: 20px;
  border: 2px dashed #eaeaea;
  border-radius: 3px; }

.checkout-review {
  padding: 15px 30px 30px;
  border: 1px solid #eaeaea;
  border-radius: 3px; }
  .checkout-review .table tr th {
    padding: 15px 0;
    font-size: 18px; }
  .checkout-review .table tr th.shipping {
    font-size: 14px; }
  .checkout-review .table tr td {
    padding: 15px 0; }
  .checkout-review .table tr td.amount {
    font-size: 18px; }

.checkout .form-control {
  border-radius: 3px; }

/* Cart */
.cart-table .table {
  border: 1px solid #eaeaea;
  margin-bottom: 0; }
  .cart-table .table tr th {
    border: none;
    padding: 15px 10px;
    color: #1c1a1f; }
  .cart-table .table tr td {
    border: none;
    border-top: 1px solid #eaeaea;
    padding: 15px 10px;
    color: #1c1a1f;
    vertical-align: middle; }
    .cart-table .table tr td.product-remove {
      padding: 15px 25px;
      text-align: center; }
    .cart-table .table tr td.product-quantity .form-control {
      width: 100px; }
    .cart-table .table tr td a {
      color: #1c1a1f; }
      .cart-table .table tr td a:hover {
        color: #21c87a; }
    .cart-table .table tr td .form-group {
      margin-bottom: 0px; }

.cart-table .actions {
  display: flex;
  border: 1px solid #eaeaea;
  border-top: none;
  padding: 15px 10px;
  flex-wrap: wrap; }
  .cart-table .actions .coupon .form-group .form-control {
    width: 330px;
    padding-right: 165px; }
  .cart-table .actions .update-cart {
    margin-left: auto; }

.cart-table .table td.product-thumbnail img {
  width: 70px;
  height: auto; }

.cart-totals {
  background: #f9f9f9;
  padding: 20px; }
  .cart-totals .cart-totals-title {
    margin-bottom: 20px;
    text-align: center; }
  .cart-totals .table tr th {
    text-align: left;
    border: none;
    border-top: 1px solid #eaeaea;
    vertical-align: top;
    background: #ffffff; }
  .cart-totals .table tr td {
    text-align: right;
    border: none;
    border-top: 1px solid #eaeaea;
    background: #ffffff; }
  .cart-totals .table tr.shipping .form-group {
    margin-bottom: 0; }
  .cart-totals .table tr.shipping .form-check-label {
    padding-left: 0px; }
  .cart-totals .table tr.shipping p {
    margin-bottom: 0; }
  .cart-totals .table tr.order-total th {
    font-size: 18px;
    font-weight: 700; }
  .cart-totals .table tr.order-total td {
    font-size: 26px;
    font-weight: 700; }
  .cart-totals .table .cart-subtotal th {
    font-size: 18px;
    font-weight: 700;
    border: none; }
  .cart-totals .table .cart-subtotal td {
    font-size: 18px;
    font-weight: 700;
    border: none; }
  .cart-totals .checkout-button {
    width: 100%;
    text-align: center;
    padding: 15px 25px; }

/*****************************
  Responsive
*****************************/
@media (max-width: 575px) {
  /* Shop cart */
  .cart-table .actions .coupon .form-group .form-control {
    width: 250px;
    padding-right: 120px; }
  .cart-table .actions .coupon .form-inline .btn {
    padding: 14px 16px; } }

/*****************************
  Not Found
*****************************/
.error-404 h1 {
  font-size: 200px;
  line-height: 1;
  color: #21c87a;
  margin-bottom: 10px; }

.error-404 h4 {
  font-size: 30px;
  margin-bottom: 30px; }

@media (max-width: 767px) {
  .error-404 h1 {
    font-size: 120px;
    line-height: 1; }
  .error-404 h4 {
    font-size: 24px;
    line-height: 36px; } }

@media (max-width: 575px) {
  /* Error 404 */
  .error-404 h1 {
    font-size: 80px;
    line-height: 1; } }

/*****************************
    Footer
*****************************/
.footer .footer-link {
  display: flex; }
  .footer .footer-link ul {
    padding-right: 50px; }
    .footer .footer-link ul:last-child {
      padding-right: 0px; }
    .footer .footer-link ul li a {
      color: #666666;
      padding: 12px 0px;
      display: block;
      font-size: 12px;
      line-height: 12px; }
    .footer .footer-link ul li:hover a {
      color: #21c87a; }
    .footer .footer-link ul li:first-child a {
      padding-top: 0; }
    .footer .footer-link ul li:last-child a {
      padding-bottom: 0; }

.footer .footer-contact-info .contact-address .contact-item a {
  color: #666666; }

.footer .footer-title {
  margin-bottom: 30px;
  color: #1c1a1f; }

.footer .social-icon a {
  color: #666666; }

.footer .footer-bottom {
  padding: 30px 0px;
  background-color: #eaeaea;
  border-top: 1px solid #eaeaea; }

.footer.footer-dark .footer-link ul li a {
  color: #999999; }
  .footer.footer-dark .footer-link ul li a:hover {
    color: #21c87a; }

.footer.footer-dark .contact-item p {
  color: #999999; }

.footer.footer-dark .contact-item a {
  color: #999999 !important; }
  .footer.footer-dark .contact-item a:hover {
    color: #21c87a !important; }

.footer.footer-dark .social-icon a {
  color: #999999; }
  .footer.footer-dark .social-icon a:hover {
    color: #21c87a; }

.footer.footer-dark .copyright p {
  color: #999999; }
  .footer.footer-dark .copyright p a {
    color: #999999; }
    .footer.footer-dark .copyright p a:hover {
      color: #21c87a; }

.footer.footer-dark p {
  color: #999999; }

.footer.footer-dark .footer-bottom {
  border-top: none; }

.footer.footer-dark .btn-primary:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #21c87a; }

/*************************
       Responsive
*************************/
@media (max-width: 991px) {
  .footer .footer-title {
    margin-bottom: 30px; } }

@media (max-width: 575px) {
  .footer-link {
    display: block; }
    .footer-link ul li:first-child a {
      padding-top: 7px; }
    .footer-link ul li:last-child a {
      padding-bottom: 7px; } }
