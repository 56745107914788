/*****************************
  List
*****************************/

  ul.list {
    padding: 0;
    margin: 0;
    li{
	    list-style: none;
	    margin-bottom: 15px;
      display: flex;
	    i{
        color: $primary;
        margin: 7px 5px 0px 0px;
        font-size: 10px;
	    }
     	&:last-child {
 	    margin-bottom: 0;
      }
      a {
        color: $gray-3;
        &:hover {
          color: $primary;
        }
      }
    }
  }
