/*****************************
  Course
*****************************/

.course {
  box-shadow: $box-shadow;
  border-radius: $border-radius-sm;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: $box-shadow-lg;
  }
  .course-img {
	position: relative;
	overflow: hidden;	
      img {
		border-radius: 3px 3px 0px 0px;
		min-height: 100%;
		object-fit: cover;
		transition: all 0.3s ease-in-out;
	  }
	  
    &:before {
      content: "";
      background: rgb(0,0,0);
      background: linear-gradient(180deg, rgba(0,0,0,0) 71%, rgba(0,0,0,1) 100%);
      position: absolute;
      bottom: 0;
      width: 100%;
	  height: 100%;
	  z-index: 1;
    }
    .course-category {
      color: $white;
      font-size: 12px;
      font-weight: 500;
      position: absolute;
      bottom: 6px;
      left: 10px;
	  right: 10px;
	  z-index: 2;
	  &:hover {
		  color: $primary;
	  }
      i {
        color: $primary;
        margin-right: 6px;
      }
    }
  }
  .course-info {
    padding: 20px 15px 15px;
    background: $white;
    border-radius: 0px 3px 3px;
    
    .course-title {
      a {
        font-size: 15px;
        font-weight: 600;
        color: $gray-7;
        &:hover {
          color: $primary;
        }
      }
    }
    .course-instructor {
      color: $gray-3;
    }
    .course-rate-price {
      display: flex;
      justify-content: space-between;
      padding-top: 15px;
      margin-top: 22px;
      border-top: 1px solid $border-color;
      .rating {
        font-size: 12px;
        font-weight: 600;
        a {
        	color: $gray-3;
        	&:hover {
        		color: $primary;
        	}
        }
        span {
          background-color: $warning;
          color: $white;
          padding: 2px 7px;
          margin-right: 8px;
          border-radius: $border-radius-sm;
        }
      }
      .price {
        font-size: 18px;
        color: $gray-7;
        font-weight: 600;
      }
    }
  }
  &:hover {
	.course-img {
		img {
			transform: scale(1.1);
		}
	}
  }
}

/* Course Filter */
.course-filter {
	position: relative;
	background: $white;
	padding: 10px;
	box-shadow: $box-shadow;
	border-radius: $border-radius-sm;

	ul {
		li {
			padding: 0px 14px;
			display: flex;

			a {
				color: $body-color;
				font-size: 12px;

				&:hover {
					color: $primary;
				}

			}

		}

		li.active {
			a {
				span {
					background: $primary;
				}

			}

		}

		&:nth-child(2n+2) {
			li {
				&:last-child {
					border-right: none;
					padding-left: 0;
					padding-right: 0px;
				}

			}

		}

	}

	.course-short {
		.select2-container {
			min-width: auto;
			margin-left: 30px;
		}

	}

	.course-view-list {
		margin-left: auto;
		align-items: center;
	}

	.course-list-icon {
		display: block;
		background: rgba($primary, 0.1);
		padding: 10px 10px 8px;
		border-radius: $border-radius-sm;

		span {
			margin: 0 auto;
			background: $primary;
			height: 2px;
			width: 10px;
			border-radius: 3px;
			margin-bottom: 2px;
			display: block;

			&:nth-child(2) {
				width: 16px;
			}

		}

		&:hover,
		.active {
			background: $primary;

			span {
				background: $white;
			}

		}

	}

	.course-list-icon.active {
		background: $primary;

		span {
			background: $white;
		}

	}

	.course-grid-icon {
		display: flex;
		background: rgba($primary, 0.1);
		color: $primary;
		padding: 10px 10px 8px;
		border-radius: $border-radius-sm;

		span {
			background: $primary;
			width: 2px;
			height: 10px;
			border-radius: 3px;
			margin: 0 1px;
			display: inline-block;

			&:nth-child(2) {
				height: 16px;
				margin-top: -3px;
			}

		}

		&:hover {
			background: $primary;

			span {
				background: $white;
			}

		}

	}

	.course-grid-icon.active {
		background: $primary;

		span {
			background: $white;
		}

  }
  
  .select2-container {
    .select2-selection--single {
      height: 40px;
    	padding: 0px 15px;
      .select2-selection__rendered {
        padding: 0px;
        line-height: 40px;
	  }  
	  .select2-selection__arrow {
		  height: 40px;
		  right: 5px;
		  b {
			border-color: $gray-3 transparent transparent transparent;
		  }
	  }  
    }
    
  }

  .select2-container--default.select2-container--open {
	.select2-selection--single {
		.select2-selection__arrow {
			b {
				border-color: transparent transparent $gray-3 transparent;
			}
		}
	}
  }


}

/* Course list */
.course-list {
	.course {
		box-shadow: none;
		.course-img {
			&:before {
				border-radius: 0px 0px 0px 3px;
			}
			img {
				border-radius: 3px 0px 0px 3px;
			}
		}
	}
}

/* Course Details */
.course-countdown {
	display: flex;  
	margin-top: 50px;
	text-align: center;  
	  .countdown {
		flex-grow: 1;
		width: 0px;
		margin: 0px 15px 0px 0px;
		&:last-child {
		  margin-right: 0;
		}
		h4 {
		  padding: 20px 0px;
		  border: 1px solid $border-color;
		  border-radius: $border-radius-sm;
		}
		p {
		  font-size: 14px;
		}
	  }
  }
  
  .meta-course {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0px;
	margin-bottom: 20px;
	li {
	  list-style: none;
	  margin-right: 20px;
	  &:last-child {
		margin-right: 0;
	  }
	  i {
		color: $primary;
		margin-right: 10px;
	  }
	}
  }
  
  .course-d-Teacher img {
	width: 40px;
	height: 40px;
	border-radius: $border-radius;
  }
  
  .course-details {
	.media-img {
	  width: 100px;
	  height: 100px;
	  border-radius: $border-radius;
	}
  }
  
  .social-share {
	  position: relative;
	  ul {
		  display: flex;
		  opacity: 0;
		  background: $primary;
		  border-radius: 3px;
		  padding: 1px 2px;
		  margin-bottom: 0;
		  right: 10px;
		  top: -3px;
		  position: absolute;
		  visibility: hidden;
		  transition: all .3s ease-in-out;
		  &:before {
			  position: absolute;
			  z-index: 999;
			  content: "";
			  right: -6px;
			  top: 9px;
			  width: 0;
			  height: 0;
			  border-style: solid;
			  border-width: 6px 0 6px 6px;
			  border-color: transparent transparent transparent $primary;
		  }
		  li {
			  line-height: 26px;
			  display: inline;
			  list-style-type: none;
			  a {
				  padding: 2px 10px 2px;
				  font-size: 14px;
				  color: $white;
				  &:hover {
					  color: $gray-7;
				  }
			  }
		  }
	  }
	  &:hover {
		  ul {
			  opacity: 1;
			  right: 25px;
			  visibility: visible;
		  }
	  }
  }

  .course-details {
	.social-share {
		ul {
			right: auto;
			left: 50%;
			top: -44px;
			transform: translate(-56%, 0%);
			&:before {
				bottom: -6px;
				top: auto;
				right: 50%;
				border-width: 6px 6px 0px 6px;
				border-color: $primary transparent transparent transparent;
				transform: translate(4px, 0px);
				}
				
			}
			&:hover {
				ul {
					top: -34px;
				}
			}
		}
  }

/* property item map */
.si-content-wrapper {
	padding: 0;
}

.custom-window.open {
	.si-frame {
		box-shadow: none;
		overflow: visible;
	}

}

.map-canvas {
	width: 100%;
	height: 100%;
}

.custom-window {
	top: 30px;
	width: 280px;
	transition: top 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	opacity: 0;
	visibility: hidden;

	.si-content {
		overflow: visible;
	}

}

.custom-window.active {
	top: 0;
	opacity: 1;
	visibility: visible;
}

.course-item-map {
	position: relative;
	max-height: inherit;
	padding: 180px 12px 12px 12px;
	box-shadow: 0 4px 16px rgba(black, 0.1);
	overflow: auto;
	z-index: 2;
	.course-item-map-content {
		.course-instructor {
			color: $gray-3;
			a {
				color: $primary;
				margin-left: 3px;
				&:hover {
					color: $gray-7;
				}
			}
		}
		.course-rate-price {
			display: flex;
	    justify-content: space-between;
	    padding-top: 15px;
	    margin-top: 15px;
	    border-top: 1px solid $border-color;
			.rating {
				span {
			    background-color: $warning;
			    color: $white;
			    padding: 2px 7px;
			    margin-right: 8px;
			    border-radius: $border-radius-sm;
				}
				a {
					color: $gray-3;
					&:hover {
						color: $primary;
					}
				}	
			}
			.price {
				font-size: 18px;
		    color: $gray-7;
		    font-weight: 600;
			}	
		}
	}
}

.si-pointer-bg-top {
	box-shadow: 0 4px 16px rgba(black, 0.1);
}

.course-item-map-img {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 180px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	z-index: 1;
}

.course-item-map-title {
	margin-top: 12px;
	line-height: 22px;
}

.course-item-map-price {
	margin-top: 8px;
	display: flex;
	align-items: center;
}

.course-item-map-content {
	p {
		margin: 0;
	}

	* {
		+ {
			p {
				margin-top: 1em;
			}

		}

	}

	a {
		color: $gray-3;

		&:hover {
			color: $primary;
		}

		&:focus {
			color: $primary;
		}

		&:active {
			color: $primary;
		}

	}

}

.custom-close {
	position: absolute;
	top: -8px;
	right: -8px;
	z-index: 9;
	width: 24px;
	padding: 0 8px;
	height: 24px;
	border-radius: $border-radius-sm;
	transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	border: 0;
	background-color: $primary;
	color: $white;
	font-size: 20px;
	line-height: 20px;
	cursor: pointer;
	transition: all 0.4s ease-in-out;

	&:hover {
		background-color: $gray-8;
	}

	&:focus {
		background-color: $gray-8;
	}

	&:active {
		background-color: $gray-8;
	}

}

.course-map {
	.map-canvas {
		height: 500px;
	}
}


/*****************************
  Responsive
*****************************/
@media (max-width:1199px) {
	.course-filter {
		.course-short {
			.select2-container {
				width: 152px !important;
			}
		}
	}
}

@media (max-width:575px) {
  .course-details {
    .media {
      display: inherit;
      img {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
  }

  .course-list {
	.course {
		.course-img {
			img {
				border-radius: 3px 3px 0px 0px;
			}
		}
	}
  }

  .course-filter {
	.course-short {
		.select2-container {
			margin-left: 0;
		}
	}
  }


}
